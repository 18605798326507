export const MAP_CONTENT_KEYS = {
  layers: {
    BACKGROUND: "bg",
  },
  objects: {
    NPC: "NPC",
    ZONES_LOUNGE: "ObjectLounge",
    ZONES_CORRIDOR_RIGHT: "rightCorridor",
    ZONES_CORRIDOR_LEFT: "leftCorridor",
    ZONES_LAB: "ObjectLab",
    ZONES_ENGINEERING_BAY: "ObjectEngineeringBay",
    ZONES_HYDROPONIC_FARM: "ObjectHydroponicFarm",
  },
} as const;

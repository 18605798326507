import Game from "./Game";
import { createCharacterAnims } from "../anims/CharacterAnims";
import HydroponicScientist from "../items/npc/HydroponicScientist";
import "../characters/MyPlayer";
import "../characters/OtherPlayer";
import OtherPlayer from "../characters/OtherPlayer";
import PlayerSelector from "../characters/PlayerSelector";
import Network from "../services/Network";
import store from "../state";
import { setLevel } from "../state/ChatStore";
import { NpcHydroponicScientist } from "../gameobjects/NpcHydroponicScientist";
import { MAP_CONTENT_KEYS } from "../constants/map-content-keys";
import { MAPS } from "../constants/maps";
import { Levels } from "../constants/levels";
import { SCENES } from "../constants/scenes";
import { removeIdList, setLocationz, setPlayerX, setPlayerY } from "../state/UserStore";

export default class SixthLevel extends Game {
  constructor() {
    super(SCENES.SIXTH_LEVEL, MAPS.sixthLevel.key);
  }

  protected init(data: any) {
    this.createLayer(data);
    this.physics.world.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels);

    const levelRightCorridor = this.map.objects.find((o) => o.name === MAP_CONTENT_KEYS.objects.ZONES_CORRIDOR_RIGHT);
    if (levelRightCorridor) {
      levelRightCorridor.objects.map((o: any) => {
        const zone = this.add.zone(o.x, o.y, o.width, o.height);
        this.physics.add.existing(zone);
        this.physics.add.overlap(zone, this.playerSelector, () => {
          if (o.properties[3].value === MAP_CONTENT_KEYS.objects.ZONES_CORRIDOR_RIGHT) {
            store.dispatch(setPlayerX(7000));
            store.dispatch(setPlayerY(3800));
          }
          this.scene.start(o.properties[1].value, {
            comesFrom: this.scene.key,
            network: this.network,
          });
        });
      });
    }
    // this.createAudio();
    this.initCamera();
    this.registerKeys();
  }

  createLayer(data: { network: Network }) {
    if (!data.network) {
      throw new Error("server instance missing");
    } else {
      this.network = data.network;
    }

    createCharacterAnims(this.anims);

    this.map = this.make.tilemap({ key: this.mapKey });
    store.dispatch(setLevel(SCENES.SIXTH_LEVEL));
    store.dispatch(removeIdList());

    store.dispatch(setLocationz(6));

    const dataState = store.getState().userGame;
    this.myPlayer = this.add.myPlayer(600, 4150, dataState.textureName ? dataState.textureName : "male", this.network.mySessionId);
    Levels.sixthLevel.layers.map((layer) => {
      const tilesetImage = this.map.addTilesetImage(layer.tilesetImage.tilesetName, layer.tilesetImage.tilesetKey);

      const newLayer = this.map.createLayer(layer.layerName, tilesetImage);
      newLayer.setCollisionByProperty({ collides: layer.collision });
      this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], newLayer);
    });

    this.playerSelector = new PlayerSelector(this, 0, 0, 256, 512);

    // don't go out of the map
    this.physics.world.bounds.width = this.map.widthInPixels;
    this.physics.world.bounds.height = this.map.heightInPixels;
    this.myPlayer.setCollideWorldBounds(true);

    // ---- NPC ----
    const hydroponicScientist = this.physics.add.staticGroup({
      classType: HydroponicScientist,
    });

    const hydroponicScientistLayer = this.map.getObjectLayer("NPC");
    hydroponicScientistLayer.objects.forEach((obj, i) => {
      if (obj.name === "HydroponicScientist") {
        const item = this.addObjectFromPoint(hydroponicScientist, obj, "hydroponicscientist", "HydroponicScientist") as HydroponicScientist;
        const id = `${i}`;
        item.id = id;
        this.hydroponicScientistMap.set(id, item);
      }
    });

    this.npcHydroponicScientist.map((npc: NpcHydroponicScientist) => {
      this.physics.add.collider(npc, this.myPlayer, npc.talk);
    });

    this.physics.add.overlap(this.playerSelector, [hydroponicScientist], this.handleItemSelectorOverlap, undefined, this);
    // [hydroponicScientist, etc],
    // ---- NPC ----

    // import other objects from Tiled map to Phaser -> use object
    Levels.sixthLevel.tiles.map((tile) => {
      this.addGroupFromTiled(tile.objectLayerName, tile.key, tile.tilesetName, tile.collidable);
    });

    this.otherPlayers = this.physics.add.group({ classType: OtherPlayer });
    for (const key in store.getState().userGame.playerLocMap) {
      console.log(store.getState().userGame.playerLocMap[key], "LOG");
      if (store.getState().userGame.playerLocMap[key].locationz == store.getState().userGame.locationz) {
        const otherPlayer = this.add.otherPlayer(
          store.getState().userGame.playerLocMap[key].x,
          store.getState().userGame.playerLocMap[key].y,
          store.getState().userGame.playerLocMap[key].anim.split("_")[0] ? store.getState().userGame.playerLocMap[key].anim.split("_")[0] : "male",
          store.getState().userGame.playerLocMap[key].id,
          store.getState().userGame.playerLocMap[key].name
        );
        this.otherPlayers.add(otherPlayer);
        this.otherPlayerMap.set(store.getState().userGame.playerLocMap[key].id, otherPlayer);
      }
    }

    this.physics.add.overlap(this.myPlayer, this.otherPlayers, this.handlePlayersOverlap, undefined, this);

    // register network event listeners
    this.network.onPlayerJoinedScene5(this.handlePlayerJoined, this);
    this.network.onPlayerLeft(this.handlePlayerLeft, this);
    this.network.onMyPlayerReady(this.handleMyPlayerReady, this);
    this.network.onMyPlayerVideoConnected(this.handleMyVideoConnected, this);
    this.network.onPlayerUpdated5(this.handlePlayerUpdated, this);
    this.network.onItemUserAdded(this.handleItemUserAdded, this);
    this.network.onItemUserRemoved(this.handleItemUserRemoved, this);
    this.network.onChatMessageAdded(this.handleChatMessageAdded, this);
  }
}

import axios from "axios";

const API_URL = "http://localhost:3002/api/v1"

export default class UserService {
    
    public static async getUserByAddress(address)
    {
        const response = await axios.get(`${API_URL}/user/${address}`);
        return response.data.result
    }

    public static async updateUser(address, body)
    {
        const response = await axios.patch(`${API_URL}/user/update/${address}`, body);
        return response.data.result
    }

    public static async addFriend(address, data)
    {
        const response = await axios.patch(`${API_URL}/user/friend/add/${address}`, data);
        return response.data.result
    }

    public static async removeFriend(address, data)
    {
        const response = await axios.patch(`${API_URL}/user/friend/remove/${address}`, data);
        return response.data.result
    }

    public static async approveFriend(address, data)
    {
        const response = await axios.patch(`${API_URL}/user/friend/approve/${address}`, data);
        return response.data.result
    }

    public static async getAllFriends(address)
    {
        const response = await axios.get(`${API_URL}/user/friend/${address}`);
        return response.data.result
    }

    public static async getAllPendingFriends(address)
    {
        const response = await axios.get(`${API_URL}/user/friend/pending/${address}`);
        return response.data.result
    }
}
import React from "react";
import {
  Box,
  Button,
  Flex,
  InjectedModalProps,
  LinkExternal,
  Message,
  Skeleton,
  Text,
} from "@pancakeswap/uikit";
import { useWeb3React } from "@web3-react/core";
import useTokenBalance, {
  FetchStatus,
  useGetBnbBalance,
} from "../../../hooks/useTokenBalance";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "../../../contexts/Localization";
import { getBscScanLink } from "../../../dex-utils";
import {
  getFullDisplayBalance,
  formatBigNumber,
} from "../../../dex-utils/formatBalance";
import tokens from "../../../config/constants/tokens";
import CopyAddress from "./CopyAddress";
import { removeTokenInLocalStorage } from "../../../services/AuthService";

interface WalletInfoProps {
  hasLowbnbBalance: boolean;
  onDismiss: any;
}

const WalletInfo: React.FC<WalletInfoProps> = ({
  hasLowbnbBalance,
  onDismiss,
}) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { balance, fetchStatus } = useGetBnbBalance();
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } =
    useTokenBalance(tokens.cake.address);
  const { logout } = useAuth();

  const handleLogout = () => {
    onDismiss();
    logout();
    removeTokenInLocalStorage();
  };
  return (
    <>
      <Text
        color="secondary"
        fontSize="12px"
        textTransform="uppercase"
        fontWeight="bold"
        mb="8px"
      >
        {t("Your Address")}
      </Text>
      <CopyAddress account={account as any} mb="24px" />
      {hasLowbnbBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold">{t("BNB Balance Low")}</Text>
            <Text as="p">{t("You need BNB for transaction fees.")}</Text>
          </Box>
        </Message>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="textSubtle">{t("BNB Balance")}</Text>
        {fetchStatus !== FetchStatus.SUCCESS ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{formatBigNumber(balance, 6)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Text color="textSubtle">{t("SUPA Balance")}</Text>
        {cakeFetchStatus !== FetchStatus.SUCCESS ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{getFullDisplayBalance(cakeBalance, 18, 3)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="end" mb="24px">
        <LinkExternal href={getBscScanLink(account as any, "address")}>
          {t("View on FTMScan")}
        </LinkExternal>
      </Flex>
      <Button variant="secondary" width="100%" onClick={handleLogout}>
        {t("Disconnect Wallet")}
      </Button>
    </>
  );
};

export default WalletInfo;

import axios from "axios";

const API_URL = "http://localhost:3002/api/v1"

interface CreateTeamMessageRequest {
    teamAddress: string
    messageType: Number
    playerName: string
    walletAddress: string
    content: string
    createdAt: Number
}

export default class TeamMessageService {
    
    public static async getMessagesByTeam(address: string)
    {
        const response = await axios.get(`${API_URL}/team_message/${address}`);
        return response.data.result
    }

    public static async createTeamMessage(data: CreateTeamMessageRequest)
    {
        const response = await axios.post(`${API_URL}/team_message/create`, data);
        return response.data.result
    }
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface YukihiraState {
  yukihiraDialogOpen: boolean;
  yukihiraId: null | string;
  yukihiraUrl: null | string;
  urls: Map<string, string>;
}

const initialState: YukihiraState = {
  yukihiraDialogOpen: false,
  yukihiraId: null,
  yukihiraUrl: null,
  urls: new Map(),
};

export const yukihiraSlice = createSlice({
  name: "yukihira",
  initialState,
  reducers: {
    openYukihiraDialog: (state, action: PayloadAction<string>) => {
      state.yukihiraDialogOpen = true;
      state.yukihiraId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.yukihiraUrl = url;
    },
    closeYukihiraDialog: (state) => {
      state.yukihiraDialogOpen = false;
      state.yukihiraId = null;
      state.yukihiraUrl = null;
    },
  },
});

export const { openYukihiraDialog, closeYukihiraDialog } = yukihiraSlice.actions;

export default yukihiraSlice.reducer;

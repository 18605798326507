export const Levels = {
  firstLevel: {
    layers: [
      {
        collision: false,
        layerName: "Floor_Tiles",
        tilesetImage: {
          tilesetName: "PropSprites (2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Floor_Tiles_2",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: false,
        layerName: "Half_Shadow",
        tilesetImage: {
          tilesetName: "Shadow_half_tile",
          tilesetKey: "Shadow_half_tile",
        },
      },
      {
        collision: false,
        layerName: "Half_Shadow_2",
        tilesetImage: {
          tilesetName: "Shadow_half_tile",
          tilesetKey: "Shadow_half_tile",
        },
      },
      {
        collision: false,
        layerName: "Quarter_Shadow",
        tilesetImage: {
          tilesetName: "Shadow_quartertile",
          tilesetKey: "Shadow_quartertile",
        },
      },
      {
        collision: false,
        layerName: "Quarter_Shadow_2",
        tilesetImage: {
          tilesetName: "Shadow_quartertile",
          tilesetKey: "Shadow_quartertile",
        },
      },
      {
        collision: false,
        layerName: "Conference_Room_Assets",
        tilesetImage: {
          tilesetName: "PropSprites (2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Conference_Room_Assets_2",
        tilesetImage: {
          tilesetName: "PropSprites (2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Conference_Room_Assets_3",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: true,
        layerName: "Collision 0.5",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: true,
        layerName: "Collision",
        tilesetImage: {
          tilesetName: "PropSprites4",
          tilesetKey: "tiles_wall_four",
        },
      },
      {
        collision: true,
        layerName: "Collision_4.5",
        tilesetImage: {
          tilesetName: "PropSprites4.5",
          tilesetKey: "tiles_wall_four_five",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_11.2",
        tilesetImage: {
          tilesetName: "PropSprites (2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_12.2",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: true,
        layerName: "Collision_2.1",
        tilesetImage: {
          tilesetName: "PropSprites (2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Collision_2",
        tilesetImage: {
          tilesetName: "PropSprites (2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Collision_2.5",
        tilesetImage: {
          tilesetName: "PropSprites (2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_11",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_11.4",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: true,
        layerName: "Collision_3",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_11.5",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: true,
        layerName: "Collision_3.1",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: true,
        layerName: "Collision_3.2",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_12",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_13",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_15",
        tilesetImage: {
          tilesetName: "PropSprites (2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Collision_5",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_14",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
    ],
    tiles: [
      {
        objectLayerName: "Counters",
        key: "tiles_wall_three",
        tilesetName: "PropSprites3",
        collidable: false,
      },
      {
        objectLayerName: "Objects_4",
        key: "tiles_wall_four",
        tilesetName: "PropSprites4",
        collidable: false,
      },
      {
        objectLayerName: "Objects_3",
        key: "tiles_wall_three",
        tilesetName: "PropSprites3",
        collidable: false,
      },
      {
        objectLayerName: "Objects_21",
        key: "tiles_wall_two",
        tilesetName: "PropSprites (2)",
        collidable: false,
      },
      {
        objectLayerName: "Objects_22",
        key: "tiles_wall_two",
        tilesetName: "PropSprites (2)",
        collidable: false,
      },
      {
        objectLayerName: "Bookshelves",
        key: "tiles_wall_two",
        tilesetName: "PropSprites (2)",
        collidable: false,
      },
      {
        objectLayerName: "Conference_Room_Assets_4",
        key: "tiles_wall_three",
        tilesetName: "PropSprites3",
        collidable: false,
      },
      {
        objectLayerName: "Objects_31",
        key: "tiles_wall_three",
        tilesetName: "PropSprites3",
        collidable: false,
      },
      {
        objectLayerName: "Objects_23",
        key: "tiles_wall_two",
        tilesetName: "PropSprites (2)",
        collidable: false,
      },
    ],
  },
  secondLevel: {
    layers: [
      {
        collision: false,
        layerName: "Floor_Tiles",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Floor_Tiles_2",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Half_Shadow",
        tilesetImage: {
          tilesetName: "Shadow half tile",
          tilesetKey: "Shadow_half_tile",
        },
      },
      {
        collision: false,
        layerName: "Quarter_Shadow",
        tilesetImage: {
          tilesetName: "Shadow Quarter",
          tilesetKey: "Shadow_quartertile",
        },
      },
      {
        collision: false,
        layerName: "Full_Shadow",
        tilesetImage: {
          tilesetName: "Shadow 1 tile",
          tilesetKey: "Shadow_1_tile",
        },
      },
      {
        collision: true,
        layerName: "Collision",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Collision_1.5",
        tilesetImage: {
          tilesetName: "PropSprites4",
          tilesetKey: "tiles_wall_four",
        },
      },
      {
        collision: true,
        layerName: "Caution_Tape",
        tilesetImage: {
          tilesetName: "PropSprites(22)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Caution_Tape_2",
        tilesetImage: {
          tilesetName: "PropSprites(22)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Collision_2",
        tilesetImage: {
          tilesetName: "PropSpritesBatch21",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
    ],
    tiles: [
      {
        objectLayerName: "Wall_Layer_1",
        key: "tiles_wall_two",
        tilesetName: "PropSprites(2)",
        collidable: false,
      },
      {
        objectLayerName: "Object_Layer_2",
        key: "tiles_wall_batch_two",
        tilesetName: "PropSpritesBatch21",
        collidable: false,
      },
      {
        objectLayerName: "Wall_Layer_2",
        key: "tiles_wall_four",
        tilesetName: "PropSprites4",
        collidable: false,
      },
    ],
  },
  thirdLevel: {
    layers: [
      {
        collision: false,
        layerName: "Floor_Tiles",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Floor_Tiles_2",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Half_Shadow",
        tilesetImage: {
          tilesetName: "Shadow half tile",
          tilesetKey: "Shadow_half_tile",
        },
      },
      {
        collision: false,
        layerName: "Quarter_Shadow",
        tilesetImage: {
          tilesetName: "Shadow Quarter",
          tilesetKey: "Shadow_quartertile",
        },
      },
      {
        collision: true,
        layerName: "Collision_0.5",
        tilesetImage: {
          tilesetName: "PropSprites4",
          tilesetKey: "tiles_wall_four",
        },
      },
      {
        collision: true,
        layerName: "Collision",
        tilesetImage: {
          tilesetName: "PropSprites4",
          tilesetKey: "tiles_wall_four",
        },
      },
      {
        collision: false,
        layerName: "Counters",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Caution Tape",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Caution Tape2",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
    ],
    tiles: [
      {
        objectLayerName: "Object_Layer_4",
        key: "tiles_wall_four",
        tilesetName: "PropSprites4",
        collidable: false,
      },
      {
        objectLayerName: "Object_Layer_2",
        key: "tiles_wall_two",
        tilesetName: "PropSprites(2)",
        collidable: false,
      },
    ],
  },
  fourthLevel: {
    layers: [
      {
        collision: false,
        layerName: "Floor_Tiles",
        tilesetImage: {
          tilesetName: "PropSprites2",
          tilesetKey: "tiles_wall",
        },
      },
      {
        collision: false,
        layerName: "Floor_Tiles_2",
        tilesetImage: {
          tilesetName: "PropSprites2",
          tilesetKey: "tiles_wall",
        },
      },
      {
        collision: false,
        layerName: "Half_Shadow",
        tilesetImage: {
          tilesetName: "Shadow Half Tile",
          tilesetKey: "Shadow_half_tile",
        },
      },
      {
        collision: false,
        layerName: "Full_Shadow",
        tilesetImage: {
          tilesetName: "Shadow 1 tile",
          tilesetKey: "Shadow_1_tile",
        },
      },
      {
        collision: false,
        layerName: "Quarter_Shadow",
        tilesetImage: {
          tilesetName: "Shadow Quarter Tile",
          tilesetKey: "Shadow_quartertile",
        },
      },
      {
        collision: true,
        layerName: "Collision",
        tilesetImage: {
          tilesetName: "PropSprites2",
          tilesetKey: "tiles_wall",
        },
      },
      {
        collision: true,
        layerName: "Collision_1.5",
        tilesetImage: {
          tilesetName: "PropSprites4",
          tilesetKey: "tiles_wall_four",
        },
      },
      {
        collision: true,
        layerName: "Collision_2",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
      {
        collision: true,
        layerName: "Caution_Tape",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
    ],
    tiles: [
      {
        objectLayerName: "Wall_Layer",
        key: "tiles_wall",
        tilesetName: "PropSprites2",
        collidable: false,
      },
      {
        objectLayerName: "Wall_Layer_2",
        key: "tiles_wall_four",
        tilesetName: "PropSprites4",
        collidable: false,
      },
      {
        objectLayerName: "Object_Layer",
        key: "tiles_wall_batch_two",
        tilesetName: "PropSprites3",
        collidable: false,
      },
    ],
  },
  fifthLevel: {
    layers: [
      {
        collision: false,
        layerName: "Floor_Tiles",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
      {
        collision: false,
        layerName: "Floor_Tiles_2",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
      {
        collision: false,
        layerName: "Floor_Tiles_3",
        tilesetImage: {
          tilesetName: "PropSprites(22)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Shadow_Half_Tile",
        tilesetImage: {
          tilesetName: "Shadow Half Tile",
          tilesetKey: "Shadow_half_tile",
        },
      },
      {
        collision: false,
        layerName: "Shadow_Half_Tile_2",
        tilesetImage: {
          tilesetName: "Shadow Half Tile",
          tilesetKey: "Shadow_half_tile",
        },
      },
      {
        collision: false,
        layerName: "Shadow_Full_Tile",
        tilesetImage: {
          tilesetName: "Shadow 1 tile",
          tilesetKey: "Shadow_1_tile",
        },
      },
      {
        collision: false,
        layerName: "Shadow_Quarter_Tile",
        tilesetImage: {
          tilesetName: "Shadow Quarter Tile",
          tilesetKey: "Shadow_quartertile",
        },
      },
      {
        collision: true,
        layerName: "Collision_0.5",
        tilesetImage: {
          tilesetName: "PropSprites4",
          tilesetKey: "tiles_wall_four",
        },
      },
      {
        collision: true,
        layerName: "Collision",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
      {
        collision: true,
        layerName: "Collision_1",
        tilesetImage: {
          tilesetName: "PropSprites4",
          tilesetKey: "tiles_wall_four",
        },
      },
      {
        collision: true,
        layerName: "Collision_2",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },

      {
        collision: true,
        layerName: "Junk_Pile",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_9",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
      {
        collision: false,
        layerName: "Tile_Layer_10",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
      {
        collision: true,
        layerName: "Caution_Tapes",
        tilesetImage: {
          tilesetName: "PropSprites(22)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Object_Layer_5",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
      {
        collision: true,
        layerName: "Object_Layer_4",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_batch_two",
        },
      },
    ],
    tiles: [
      {
        objectLayerName: "Object_Layer_2",
        key: "tiles_wall_batch_two",
        tilesetName: "PropSprites3",
        collidable: false,
      },
    ],
  },
  sixthLevel: {
    layers: [
      {
        collision: false,
        layerName: "Floor_Tiles",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: false,
        layerName: "Floor_Tiles_2",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: false,
        layerName: "Floor_Tiles_3",
        tilesetImage: {
          tilesetName: "PropSprites4",
          tilesetKey: "tiles_wall_four",
        },
      },
      {
        collision: false,
        layerName: "Half_Shadow",
        tilesetImage: {
          tilesetName: "HalfShadow",
          tilesetKey: "Shadow_half_tile",
        },
      },
      {
        collision: false,
        layerName: "Half_Shadow_2",
        tilesetImage: {
          tilesetName: "HalfShadow",
          tilesetKey: "Shadow_half_tile",
        },
      },
      {
        collision: false,
        layerName: "Quarter_Shadow",
        tilesetImage: {
          tilesetName: "QuarterShadow",
          tilesetKey: "Shadow_quartertile",
        },
      },
      {
        collision: false,
        layerName: "Plot",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: true,
        layerName: "Collision",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: true,
        layerName: "Collision_1.2",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Collision_1.5",
        tilesetImage: {
          tilesetName: "PropSprites4",
          tilesetKey: "tiles_wall_four",
        },
      },
      {
        collision: true,
        layerName: "Collision_2",
        tilesetImage: {
          tilesetName: "PropSprites3",
          tilesetKey: "tiles_wall_three",
        },
      },
      {
        collision: true,
        layerName: "Collision_3",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Caution_Tape",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
      {
        collision: true,
        layerName: "Caution_Tape_2",
        tilesetImage: {
          tilesetName: "PropSprites(2)",
          tilesetKey: "tiles_wall_two",
        },
      },
    ],
    tiles: [
      {
        objectLayerName: "Object_Layer",
        key: "tiles_wall_three",
        tilesetName: "PropSprites3",
        collidable: false,
      },
      {
        objectLayerName: "Object_Layer_1.1",
        key: "tiles_wall_three",
        tilesetName: "PropSprites3",
        collidable: false,
      },
      {
        objectLayerName: "Object_Layer_1.2",
        key: "tiles_wall_four",
        tilesetName: "PropSprites4",
        collidable: false,
      },
      {
        objectLayerName: "Object_Layer_1",
        key: "tiles_wall_two",
        tilesetName: "PropSprites(2)",
        collidable: false,
      },
      {
        objectLayerName: "Object_Layer_2",
        key: "tiles_wall_three",
        tilesetName: "PropSprites3",
        collidable: false,
      },
    ],
  },
};

import React, { useState } from "react";
import { Box, CopyIcon, Flex, FlexProps, IconButton } from "@pancakeswap/uikit";
import styled from "styled-components";
import { useTranslation } from "../../../contexts/Localization";

interface CopyAddressPlayerProps extends FlexProps {
  account: string;
}

const Wrapper = styled(Flex)`
  align-items: center;
  position: relative;
  height: 20px;
  color: #f4eeff;
`;

const Address = styled.div`
  flex: 1;
  position: relative;

  & > input {
    background: transparent;
    border: 0;
    display: block;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    width: 101%;

    &:focus {
      outline: 0;
    }
  }

  &:after {
    content: "";
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
`;

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) =>
    isTooltipDisplayed ? "inline-block" : "none"};
  position: absolute;
  top: -22px;
  right: 0;
  text-align: center;
  opacity: 0.7;
  width: 100px;
`;

const CopyAddressPlayer: React.FC<CopyAddressPlayerProps> = ({
  account,
  ...props
}) => {
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
  const { t } = useTranslation();

  const copyAddressPlayer = () => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(account).then(() => displayTooltip());
    } else if (document.queryCommandSupported("copy")) {
      const ele = document.createElement("textarea");
      ele.value = account;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand("copy");
      document.body.removeChild(ele);
      displayTooltip();
    }
  };

  function displayTooltip() {
    setIsTooltipDisplayed(true);
    setTimeout(() => {
      setIsTooltipDisplayed(false);
    }, 1000);
  }

  return (
    <Box position="relative" {...props}>
      <Wrapper>
        <Address title={account}>
          <input type="text" readOnly value={account} />
        </Address>
        <IconButton
          variant="text"
          onClick={copyAddressPlayer}
          style={{ width: 40, color: "#F4EEFF" }}
        >
          ...&nbsp; <CopyIcon color="#F4EEFF" width="18px" />
        </IconButton>
      </Wrapper>
      <Tooltip isTooltipDisplayed={isTooltipDisplayed}>{t("Copied")}</Tooltip>
    </Box>
  );
};

export default CopyAddressPlayer;

import React from "react";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { closeLoopyDialog } from "../../state/npc/LoopyStore";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Level from "../../utils/level";
import store from "../../state";

const Description = styled.div`
  font-size: 16px;
  color: #c2c2c2;
  text-align: justify;
  img {
    max-width: 100%;
    height: auto;
    borderradius: 16;
    margintop: "2.5em";
  }
`;

export default function LoopyDialog() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(true);
  const [openChild, setOpenChild] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    const currentLevel = Level.getLevelByName(store.getState().chat.levelName);
    if (currentLevel) currentLevel.enableKeys();
    dispatch(closeLoopyDialog());
  };

  const handleOpenChild = () => {
    setOpenChild(true);
    setOpen(false);
  };

  const handleCloseChild = () => {
    setOpenChild(false);
    const currentLevel = Level.getLevelByName(store.getState().chat.levelName);
    if (currentLevel) currentLevel.enableKeys();
    dispatch(closeLoopyDialog());
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          elevation: 0,
          sx: {},
          style: {
            backgroundColor: "#222639",
          },
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Description>test 1</Description>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose} fullWidth>
            No
          </Button>
          <Button variant="contained" color="secondary" onClick={handleOpenChild} fullWidth>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openChild}
        onClose={handleCloseChild}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        PaperProps={{
          elevation: 0,
          sx: {},
          style: {
            backgroundColor: "#222639",
          },
        }}
      >
        <DialogContent>
          <Description>test 2</Description>
        </DialogContent>
      </Dialog>
    </>
  );
}

import { ItemType } from "../../../../types/Items";
import Item from "../Item";
import store from "../../state";
import { openChlamydiaDialog } from "../../state/npc/ChlamydiaStore";
import Level from "../../utils/level";

export default class Chlamydia extends Item {
  id?: string;
  currentUsers = new Set<string>();
  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
    super(scene, x, y, texture, frame);

    this.itemType = ItemType.CHLAMYDIA;
  }

  private updateStatus() {
    if (!this.currentUsers) return;
    const numberOfUsers = this.currentUsers.size;
    this.clearStatusBox();
    if (numberOfUsers === 1) {
      this.setStatusBox(`${numberOfUsers} user`);
    } else if (numberOfUsers > 1) {
      this.setStatusBox(`${numberOfUsers} users`);
    }
  }

  onOverlapDialog() {
    if (this.currentUsers.size === 0) {
      this.setDialogBox("Press 'R' to interact");
    } else {
      this.setDialogBox("Press R join");
    }
  }

  addCurrentUser(userId: string) {
    if (!this.currentUsers || this.currentUsers.has(userId)) return;
    this.currentUsers.add(userId);
    this.updateStatus();
  }

  removeCurrentUser(userId: string) {
    if (!this.currentUsers || !this.currentUsers.has(userId)) return;
    this.currentUsers.delete(userId);
    this.updateStatus();
  }

  openDialog() {
    if (!this.id) return;
    const currentLevel = Level.getLevelByName(store.getState().chat.levelName);
    if (currentLevel) currentLevel.disableKeys();
    store.dispatch(openChlamydiaDialog(this.id));
  }
}

import Phaser from "phaser";
import PlayerSelector from "./PlayerSelector";
import { PlayerBehavior } from "../../../types/PlayerBehavior";
import { sittingShiftData } from "./Player";
import Player from "./Player";
import Network from "../services/Network";
import Chair from "../items/Chair";
import Computer from "../items/Computer";
import Whiteboard from "../items/Whiteboard";
import Chlamydia from "../items/npc/Chlamydia";
import Engineer from "../items/npc/Engineer";
import HydroponicScientist from "../items/npc/HydroponicScientist";
import LabScientist from "../items/npc/LabScientist";
import Loopy from "../items/npc/Loopy";
import MadMarv from "../items/npc/MadMarv";
import Merchant from "../items/npc/Merchant";
import Microbiologist from "../items/npc/Microbiologist";
import SpecimenDealer from "../items/npc/SpecimenDealer";
import Stan from "../items/npc/Stan";
import TokenTrader from "../items/npc/TokenTrader";
import Yukihira from "../items/npc/Yukihira";

import { phaserEvents, Event } from "../events/EventCenter";
import store from "../state";
// import { pushPlayerJoinedMessage } from "../state/ChatStore";
import { ItemType } from "../../../types/Items";

export default class MyPlayer extends Player {
  private playContainerBody: Phaser.Physics.Arcade.Body;
  private chairOnSit?: Chair;
  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, id: string, frame?: string | number) {
    super(scene, x, y, texture, id, frame);
    this.playContainerBody = this.playerContainer.body as Phaser.Physics.Arcade.Body;
  }

  setPlayerName(name: string) {
    console.log("myPlayer: ", name);
    this.playerName.setText(name);
    phaserEvents.emit(Event.MY_PLAYER_NAME_CHANGE, name);
    // store.dispatch(pushPlayerJoinedMessage(name));
  }

  setPlayerTexture(texture: string) {
    this.playerTexture = texture;
    this.anims.play(`${this.playerTexture}_idle_down`, true);
    phaserEvents.emit(Event.MY_PLAYER_TEXTURE_CHANGE, this.x, this.y, this.anims.currentAnim.key);
  }

  update(playerSelector: PlayerSelector, cursors: Phaser.Types.Input.Keyboard.CursorKeys, keyE: Phaser.Input.Keyboard.Key, keyR: Phaser.Input.Keyboard.Key, network: Network) {
    if (!cursors) return;

    const item = playerSelector.selectedItem;

    if (Phaser.Input.Keyboard.JustDown(keyR)) {
      switch (item?.itemType) {
        case ItemType.COMPUTER:
          const computer = item as Computer;
          computer.openDialog(this.playerId, network);
          break;
        case ItemType.WHITEBOARD:
          const whiteboard = item as Whiteboard;
          whiteboard.openDialog(network);
          break;
        case ItemType.MICROBIOLOGIST:
          // hacky and hard-coded, but leaving it as is for now
          // window.open("https://www.buymeacoffee.com/skyoffice", "_blank");
          const microBiologist = item as Microbiologist;
          microBiologist.openDialog();
          break;
        case ItemType.SPECIMENDEALER:
          const specimenDealer = item as SpecimenDealer;
          specimenDealer.openDialog();
          break;
        case ItemType.TOKENTRADER:
          const tokenTrader = item as TokenTrader;
          tokenTrader.openDialog();
          break;
        case ItemType.HYDROPONICSCIENTIST:
          const hydroponicScientist = item as HydroponicScientist;
          hydroponicScientist.openDialog();
          break;
        case ItemType.LABSCIENTIST:
          const labScientist = item as LabScientist;
          labScientist.openDialog();
          break;
        case ItemType.ENGINEER:
          const engineer = item as Engineer;
          engineer.openDialog();
          break;
        case ItemType.MADMARV:
          const madmarv = item as MadMarv;
          madmarv.openDialog();
          break;
        case ItemType.CHLAMYDIA:
          const chlamydia = item as Chlamydia;
          chlamydia.openDialog();
          break;
        case ItemType.STAN:
          const stan = item as Stan;
          stan.openDialog();
          break;
        case ItemType.YUKIHIRA:
          const yukihara = item as Yukihira;
          yukihara.openDialog();
          break;
        case ItemType.LOOPY:
          const loopy = item as Loopy;
          loopy.openDialog();
          break;
        case ItemType.MERCHANT:
          const merchant = item as Merchant;
          merchant.openDialog();
          break;
      }
    }

    switch (this.playerBehavior) {
      case PlayerBehavior.IDLE:
        // if press E in front of selected chair
        if (Phaser.Input.Keyboard.JustDown(keyE) && item?.itemType === ItemType.CHAIR) {
          const chairItem = item as Chair;
          /**
           * move player to the chair and play sit animation
           * a delay is called to wait for player movement (from previous velocity) to end
           * as the player tends to move one more frame before sitting down causing player
           * not sitting at the center of the chair
           */
          this.scene.time.addEvent({
            delay: 10,
            callback: () => {
              // update character velocity and position
              this.setVelocity(0, 0);
              if (chairItem.itemDirection) {
                this.setPosition(chairItem.x + sittingShiftData[chairItem.itemDirection][0], chairItem.y + sittingShiftData[chairItem.itemDirection][1]).setDepth(
                  chairItem.depth + sittingShiftData[chairItem.itemDirection][2]
                );
                // also update playerNameContainer velocity and position
                this.playContainerBody.setVelocity(0, 0);
                this.playerContainer.setPosition(chairItem.x + sittingShiftData[chairItem.itemDirection][0], chairItem.y + sittingShiftData[chairItem.itemDirection][1] - 30);
              }

              this.play(`${this.playerTexture}_sit_${chairItem.itemDirection}`, true);
              playerSelector.selectedItem = undefined;
              if (chairItem.itemDirection === "up") {
                playerSelector.setPosition(this.x, this.y - this.height);
              } else {
                playerSelector.setPosition(0, 0);
              }
              // send new location and anim to server
              network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, store.getState().userGame.locationz, this.playerId);
            },
            loop: false,
          });
          // set up new dialog as player sits down
          chairItem.clearDialogBox();
          chairItem.setDialogBox("Press E to leave");
          this.chairOnSit = chairItem;
          this.playerBehavior = PlayerBehavior.SITTING;
          return;
        }

        const speed = 950;
        let vx = 0;
        let vy = 0;
        if (cursors.left?.isDown) vx -= speed;
        if (cursors.right?.isDown) vx += speed;
        if (cursors.up?.isDown) {
          vy -= speed;
          this.setDepth(this.y); //change player.depth if player.y changes
        }
        if (cursors.down?.isDown) {
          vy += speed;
          this.setDepth(this.y); //change player.depth if player.y changes
        }
        // update character velocity
        this.setVelocity(vx, vy);
        this.body.velocity.setLength(speed);
        // also update playerNameContainer velocity
        this.playContainerBody.setVelocity(vx, vy);
        this.playContainerBody.velocity.setLength(speed);

        this.playContainerBody.x = this.body.position.x + this.playContainerBody.width / 2;
        this.playContainerBody.y = this.body.position.y - 30;

        // this.playerName2 = this.scene.add

        // .text(0, 0, dataState.namePlayer ? dataState.namePlayer : "")
        // .setFontFamily("Arial")
        // .setFontSize(50)
        // .setColor("#000000")
        // .setOrigin(0.5);
        // update animation according to velocity and send new location and anim to server
        if (vx !== 0 || vy !== 0) network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, store.getState().userGame.locationz, this.playerId);
        if (vx > 0) {
          this.play(`${this.playerTexture}_run_right`, true);
        } else if (vx < 0) {
          this.play(`${this.playerTexture}_run_left`, true);
        } else if (vy > 0) {
          this.play(`${this.playerTexture}_run_down`, true);
        } else if (vy < 0) {
          this.play(`${this.playerTexture}_run_up`, true);
        } else {
          const parts = this.anims.currentAnim.key.split("_");
          parts[1] = "idle";
          const newAnim = parts.join("_");
          // this prevents idle animation keeps getting called
          if (this.anims.currentAnim.key !== newAnim) {
            this.play(parts.join("_"), true);
            // send new location and anim to server
            network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, store.getState().userGame.locationz, this.playerId);
          }
        }
        break;

      case PlayerBehavior.SITTING:
        // back to idle if player press E while sitting
        if (Phaser.Input.Keyboard.JustDown(keyE)) {
          const parts = this.anims.currentAnim.key.split("_");
          parts[1] = "idle";
          this.play(parts.join("_"), true);
          this.playerBehavior = PlayerBehavior.IDLE;
          this.chairOnSit?.clearDialogBox();
          playerSelector.setPosition(this.x, this.y);
          playerSelector.update(this, cursors);
          network.updatePlayer(this.x, this.y, this.anims.currentAnim.key, store.getState().userGame.locationz, this.playerId);
        }
        break;
    }
  }
}

declare global {
  namespace Phaser.GameObjects {
    interface GameObjectFactory {
      myPlayer(x: number, y: number, texture: string, id: string, frame?: string | number): MyPlayer;
    }
  }
}

Phaser.GameObjects.GameObjectFactory.register("myPlayer", function (this: Phaser.GameObjects.GameObjectFactory, x: number, y: number, texture: string, id: string, frame?: string | number) {
  const sprite = new MyPlayer(this.scene, x, y, texture, id, frame);

  this.displayList.add(sprite);
  this.updateList.add(sprite);

  this.scene.physics.world.enableBody(sprite, Phaser.Physics.Arcade.DYNAMIC_BODY);

  const collisionScale = [0.5, 0.2];
  sprite.body.setSize(sprite.width * collisionScale[0], sprite.height * collisionScale[1]).setOffset(sprite.width * (1 - collisionScale[0]) * 0.5, sprite.height * (1 - collisionScale[1]));

  return sprite;
});

import phaserGame from "../PhaserGame";
import FirstLevel from "../scenes/FirstLevel";
import SecondLevel from "../scenes/SecondLevel";
import ThirdLevel from "../scenes/ThirdLevel";
import FourthLevel from "../scenes/FourthLevel";
import FifthLevel from "../scenes/FifthLevel";
import SixthLevel from "../scenes/SixthLevel";
import { SCENES } from "../constants/scenes";

export default class Level {
  public static getLevelByName = (levelName: string) => {
    let level: FirstLevel | SecondLevel | ThirdLevel | FourthLevel | FifthLevel | SixthLevel | null = null;
    switch (levelName) {
      case SCENES.FIRST_LEVEL:
        level = phaserGame.scene.keys.FirstLevel as FirstLevel;
        break;
      case SCENES.SECOND_LEVEL:
        level = phaserGame.scene.keys.SecondLevel as SecondLevel;
        break;
      case SCENES.THIRD_LEVEL:
        level = phaserGame.scene.keys.ThirdLevel as ThirdLevel;
        break;
      case SCENES.FOURTH_LEVEL:
        level = phaserGame.scene.keys.FourthLevel as FourthLevel;
        break;
      case SCENES.FIFTH_LEVEL:
        level = phaserGame.scene.keys.FifthLevel as FifthLevel;
        break;
      case SCENES.SIXTH_LEVEL:
        level = phaserGame.scene.keys.SixthLevel as SixthLevel;
        break;
    }
    return level;
  };
}

import axios from "axios";

const API_URL = "http://localhost:3002/api/v1"

interface CreateTeamRequest {
    teamName: string
    teamAddress: string
}

interface Request {
    userAddress: string
    teamId: string
}

export default class TeamService {
    
    public static async getAllTeamsByUserAddress(address: any, approved: boolean)
    {
        const response = await axios.get(`${API_URL}/team/getbyuser/${address}?approved=${approved}`);
        return response.data.result
    }

    public static async getTeamById(teamId: string)
    {
        const response = await axios.get(`${API_URL}/team/${teamId}`);
        return response.data.result
    }

    public static async createTeam(data: CreateTeamRequest)
    {
        const response = await axios.post(`${API_URL}/team/create`, data);
        return response.data
    }

    public static async joinTeam(data: Request)
    {
        const response = await axios.patch(`${API_URL}/team/join`, data);
        return response.data.result
    }

    public static async inviteUser(data: Request)
    {
        const response = await axios.patch(`${API_URL}/team/invite`, data);
        return response.data.result
    }

    public static async removeMember(data: Request)
    {
        const response = await axios.patch(`${API_URL}/team/member/remove`, data);
        return response.data.result
    }

    public static async leaveTeam(data: Request)
    {
        const response = await axios.patch(`${API_URL}/team/leave`, data);
        return response.data.result
    }
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MadMarvState {
  madMarvDialogOpen: boolean;
  madMarvId: null | string;
  madMarvUrl: null | string;
  urls: Map<string, string>;
}

const initialState: MadMarvState = {
  madMarvDialogOpen: false,
  madMarvId: null,
  madMarvUrl: null,
  urls: new Map(),
};

export const madMarvSlice = createSlice({
  name: "madMarv",
  initialState,
  reducers: {
    openMadMarvDialog: (state, action: PayloadAction<string>) => {
      state.madMarvDialogOpen = true;
      state.madMarvId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.madMarvUrl = url;
    },
    closeMadMarvDialog: (state) => {
      state.madMarvDialogOpen = false;
      state.madMarvId = null;
      state.madMarvUrl = null;
    },
  },
});

export const { openMadMarvDialog, closeMadMarvDialog } = madMarvSlice.actions;

export default madMarvSlice.reducer;

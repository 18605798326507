import React, { useEffect } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useAppDispatch } from "../../hooks";
import { closeTokenTraderDialog } from "../../state/npc/TokenTraderStore";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Swap from "../../views/Swap";
import { ResetCSS } from "@pancakeswap/uikit";
import GlobalStyle from "../../style/Global";
import Level from "../../utils/level";
import store from "../../state";

const Description = styled.div`
  font-size: 16px;
  color: #c2c2c2;
  text-align: justify;
  img {
    max-width: 100%;
    height: auto;
    borderradius: 16;
    margintop: "2.5em";
  }
`;

const SwapParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .close {
    background: #222639;
    color: #eee;
    z-index: 2;
    bottom: 4px;
  }

  .close:hover {
    background: rgba(34, 38, 57, 0.8);
  }
`;

export default function TokenTraderDialog() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(true);
  const [openChild, setOpenChild] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    const currentLevel = Level.getLevelByName(store.getState().chat.levelName);
    if (currentLevel) currentLevel.enableKeys();
    dispatch(closeTokenTraderDialog());
  };

  const handleOpenChild = () => {
    setOpenChild(true);
    setOpen(false);
  };

  const handleCloseChild = () => {
    const currentLevel = Level.getLevelByName(store.getState().chat.levelName);
    if (currentLevel) currentLevel.enableKeys();
    dispatch(closeTokenTraderDialog());
    setOpenChild(false);
  };

  return (
    <>
      <ResetCSS />
      <GlobalStyle />
      {/* Swap dialog: 2 */}
      {/* Currency selection dialog: 3 */}

      {openChild && (
        <SwapParent>
          <IconButton onClick={handleCloseChild} color="primary" aria-label="close" component="span" className="close">
            <CloseIcon />
          </IconButton>
          <Swap />
        </SwapParent>
      )}
      {/* Trader dialog: 1 */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          elevation: 0,
          sx: {},
          style: {
            backgroundColor: "#222639",
          },
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Description>Ahhhhh, a well-versed trader I see. Interesting. Let’s trade.</Description>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose} fullWidth>
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenChild}
            // OnClick -> Opens Swap Component
            fullWidth
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LabScientistState {
  labScientistDialogOpen: boolean;
  labScientistId: null | string;
  labScientistUrl: null | string;
  urls: Map<string, string>;
}

const initialState: LabScientistState = {
  labScientistDialogOpen: false,
  labScientistId: null,
  labScientistUrl: null,
  urls: new Map(),
};

export const labScientistSlice = createSlice({
  name: "labScientist",
  initialState,
  reducers: {
    openLabScientistDialog: (state, action: PayloadAction<string>) => {
      state.labScientistDialogOpen = true;
      state.labScientistId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.labScientistUrl = url;
    },
    closeLabScientistDialog: (state) => {
      state.labScientistDialogOpen = false;
      state.labScientistId = null;
      state.labScientistUrl = null;
    },
  },
});

export const { openLabScientistDialog, closeLabScientistDialog } = labScientistSlice.actions;

export default labScientistSlice.reducer;

import React, { useEffect, useState } from "react";
import { Flex, Skeleton, Text } from "@pancakeswap/uikit";
import { useWeb3React } from "@web3-react/core";
import { FetchStatus, useGetBnbBalance } from "../../../hooks/useTokenBalance";
import { useTranslation } from "../../../contexts/Localization";
import CheckIcon from "@mui/icons-material/Check";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import CopyAddressOtherPlayer from "./CopyAddressOtherPlayer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircleIcon from "@mui/icons-material/Circle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { useAppDispatch } from "../../../hooks";
import { setIsCreateTeam, setIsApproveInvitation, setIsDeleteTeam, setIsLeaveTeam, setRemovedMember } from "../../../state/ChatStore";
import TeamService from "../../../services/TeamService";
import UserService from "../../../services/UserService";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));

interface ProfileTeamProps {
  onDismiss: any;
  walletAddress?: string;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

enum TeamTab {
  CREATE_TEAM,
  MY_TEAM
}

enum ConfirmType {
  DECLINE_INVITATION,
  REMOVE_MEMBER,
  LEAVE_TEAM
}

const ProfileTeam: React.FC<ProfileTeamProps> = ({ onDismiss, walletAddress }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { fetchStatus } = useGetBnbBalance();
  const [activeTab, setActiveTab] = React.useState<TeamTab>(TeamTab.CREATE_TEAM);
  const [confirmPopOver, setConfirmPopOver] =
    React.useState<HTMLButtonElement | null>(null);
  const [teamName, setTeamName] = useState<string>("");
  const [pendingTeams, setPendingTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState<any>(null);
  const [confirmedInfo, setConfirmedInfo] = useState<any>({type: "", teamId: "", userAddress: "", message: ""});
  const [error, setError] = useState<string>("");
  const { account } = useWeb3React();
  
  const loadPendingTeams = async () => {
    const teams = await TeamService.getAllTeamsByUserAddress(walletAddress, false)
    setPendingTeams(teams ?? [])
  }

  const loadCurrentTeam = async () => {
    const user = await UserService.getUserByAddress(walletAddress)
    if(user && user.team){
      const team = await TeamService.getTeamById(user.team)
      if(team) {
        setCurrentTeam(team)
        setActiveTab(TeamTab.MY_TEAM)
      }else {
        setCurrentTeam(null)
        setActiveTab(TeamTab.CREATE_TEAM)
      }
    }
  }

  const createTeam = async () => {
    if(teamName){
      if(walletAddress){
        const data = {teamName: teamName, teamAddress: walletAddress}
        if(currentTeam && currentTeam.teamAddress === walletAddress) await TeamService.leaveTeam({teamId: currentTeam._id, userAddress: account ?? ""})
        const response = await TeamService.createTeam(data)
        if(response.result) {
          setCurrentTeam(response.result)
          setPendingTeams([])
          setActiveTab(TeamTab.MY_TEAM)
          dispatch(setIsCreateTeam(true))
        }else{
          setError(response.message)
        }
      }
    }else{
        setError("Team name is required")
    }
  }

  const approveTeam = async (teamId: string) => {
    if(walletAddress){
      const data = {userAddress: walletAddress, teamId}
      if(currentTeam && currentTeam.teamAddress === walletAddress) await TeamService.leaveTeam({teamId: currentTeam._id, userAddress: account ?? ""})
      await TeamService.joinTeam(data)
      loadCurrentTeam()
      setPendingTeams([])
      setActiveTab(TeamTab.MY_TEAM)
      dispatch(setIsApproveInvitation(true))
    }
  }

  const removeMember = async (teamId: string, userAddress: string) => {
    const data = {userAddress, teamId} 
    await TeamService.removeMember(data)
    loadCurrentTeam()
    loadPendingTeams()
  }

  const leaveTeam = async (teamId: string, userAddress: string) => {
    await TeamService.leaveTeam({teamId, userAddress})
    loadPendingTeams()
    setCurrentTeam(null)
    setActiveTab(TeamTab.CREATE_TEAM)
    if(currentTeam.teamAddress === userAddress){
      dispatch(setIsDeleteTeam(true))
    }else{
      dispatch(setIsLeaveTeam(true))
    }
  }

  useEffect(() => {
    if(walletAddress) {
      loadPendingTeams()
      loadCurrentTeam()
    }
  }, [walletAddress])

  const handleChangeTab = (event: React.SyntheticEvent, activeTab: number) => {
    setActiveTab(activeTab);
  };

  const handleClickConfirmPopOver = (
    event: React.MouseEvent<HTMLButtonElement>, type: ConfirmType, teamId: string, userAddress: any, message: string
  ) => {
    setConfirmedInfo({type, teamId, userAddress, message})
    setConfirmPopOver(event.currentTarget);
  };

  const handleAcceptConfirmPopOver = () => {
    if(confirmedInfo.type === ConfirmType.DECLINE_INVITATION || confirmedInfo.type === ConfirmType.REMOVE_MEMBER){
      removeMember(confirmedInfo.teamId, confirmedInfo.userAddress)
      if(confirmedInfo.type === ConfirmType.REMOVE_MEMBER) dispatch(setRemovedMember(confirmedInfo.userAddress))
    }else if(confirmedInfo.type === ConfirmType.LEAVE_TEAM){
      if(walletAddress) leaveTeam(confirmedInfo.teamId, confirmedInfo.userAddress)
    }
    setConfirmPopOver(null);
  };

  const handleCloseConfirmPopOver = () => {
    setConfirmedInfo({type: "", id: "", message: ""})
    setConfirmPopOver(null);
  };

  const openConfirmPopOver = Boolean(confirmPopOver);
  const idOpenConfirmPopOver = openConfirmPopOver ? "simple-popover" : undefined;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            label={<span style={{ color: "#B8ADD2" }}>Create Team</span>}
            {...a11yProps(0)}
            onClick={() => setActiveTab(TeamTab.CREATE_TEAM)}
            style={{display: (pendingTeams && pendingTeams.length > 0 || !currentTeam) && account === walletAddress ? "block" : "none"}}
          />
          <Tab
            label={<span style={{ color: "#B8ADD2" }}>My Team</span>}
            {...a11yProps(0)}
            onClick={() => setActiveTab(TeamTab.MY_TEAM)}
            style={{display: activeTab === TeamTab.MY_TEAM ? "block" : "none"}}
          />
        </Tabs>
      </Box>
      {/* Pop Over Delete */}
      <Popover
        id={idOpenConfirmPopOver}
        open={openConfirmPopOver}
        anchorEl={confirmPopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            padding: 2,
            backgroundColor: "#222639",
            color: "#eee",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align={"center"}>
                 {confirmedInfo.message}
              </Typography>
            </Grid>
            <Grid item xs>
              <Flex alignItems="center" justifyContent="space-between">
                <Button
                  sx={{ marginTop: 2, marginRight: 2 }}
                  variant="outlined"
                  color="secondary"
                  onClick={handleCloseConfirmPopOver}
                  fullWidth
                >
                  No
                </Button>
                <Button
                  sx={{ marginTop: 2 }}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleAcceptConfirmPopOver}
                >
                  Yes
                </Button>
              </Flex>
            </Grid>
          </Grid>
        </Box>
      </Popover>

      {/* Create Team */}
      <TabPanel value={activeTab} index={TeamTab.CREATE_TEAM}>
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
          }}
        >{account === walletAddress && <>
        <Box mt={3} ml={2}>
            <Flex alignItems="center" justifyContent="space-between">
              <TextField
                fullWidth
                label="Team name"
                color="secondary"
                sx={{ input: { color: "white", height: 12 } }}
                focused
                onInput={(e) => {
                  const value = (e.target as HTMLInputElement).value
                  if(value){
                    setError("")
                    setTeamName(value);
                  }else{
                    setError("Team name is required")
                  }
                }}
                error={!!error}
              />
              <Button
                sx={{ marginLeft: 1 }}
                variant="contained"
                color="secondary"
                onClick={createTeam}
              >
                Create
              </Button>
            </Flex>
            <p style={{width: "100%", color:"#d32f2f", fontWeight: 400, fontSize: "0.75rem"}}>{error}</p>
          </Box>
          {pendingTeams && pendingTeams.length > 0 && <Text ml={3} mt={4} color="textSubtle">
            Pending Invites
          </Text>}
          <Box
            mt={2}
            sx={{
              flexGrow: 1,
              overflowY: "scroll",
              width: "100%",
              height: 300,
            }}
          >
            {pendingTeams && pendingTeams.map((team: any) => <StyledPaper
              sx={{
                my: 1,
                mx: "auto",
                p: 2,
              }}
              key={team._id}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs>
                  <Typography noWrap>{team.teamName}</Typography>
                </Grid>
                <Grid item>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <IconButton 
                      color="primary" 
                      aria-label="check" 
                      size="large"
                      onClick={() => approveTeam(team._id)}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="reject"
                      size="large"
                      onClick={(event) => handleClickConfirmPopOver(event, ConfirmType.DECLINE_INVITATION, team._id, walletAddress, `Are you sure you want to decline "${team.teamName}"?`)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </StyledPaper>)}
          </Box>
        </>}
        </Box>
      </TabPanel>
      {/* My Team */}
      <TabPanel value={activeTab} index={TeamTab.MY_TEAM}>
      {currentTeam && <Box
          sx={{
            flexGrow: 1,
            width: "100%",
          }}
        >
          <Box mt={2} mb={2} ml={2} mr={3}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="textSubtle">{t("Team Name:")}</Text>
                  {fetchStatus !== FetchStatus.SUCCESS ? (
                    <Skeleton height="22px" width="60px" />
                  ) : (
                    <Text>{currentTeam.teamName}</Text>
                  )}
                  {account === walletAddress && <Button
                    sx={{ marginLeft: 1 }}
                    variant="contained"
                    color="error"
                    onClick={(event) => handleClickConfirmPopOver(event, ConfirmType.LEAVE_TEAM, currentTeam._id, walletAddress, `Are you sure you want to leave "${currentTeam.teamName}"?`)}
                  >
                    Leave
                  </Button>}
                </Flex>
              </Grid>
            </Grid>
          </Box>
          {currentTeam.teamMembers && currentTeam.teamMembers.length>0 && <Text ml={3} color="textSubtle">
            Team Member:
          </Text>}
          <Box
            mt={2}
            sx={{
              flexGrow: 1,
              overflowY: "scroll",
              width: "100%",
              height: 300,
            }}
          >
            {currentTeam && currentTeam.teamMembers && currentTeam.teamMembers.map((member: any, index: number) => <StyledPaper
              sx={{
                my: 1,
                mx: "auto",
                p: 2,
              }}
              key={index}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs zeroMinWidth>
                  <Typography noWrap>{`${member.playerName} ${member.approved ? "" : "(pending)"}`}</Typography>
                  <CopyAddressOtherPlayer account={member.walletAddress as any} />
                </Grid>
                <Grid item>
                  <Stack direction="row" alignItems="center" spacing={1}>
                  {member.walletAddress !== currentTeam.teamAddress && <IconButton
                    color="primary"
                    aria-label="reject"
                    size="large"
                    onClick={(event) => {
                      if(walletAddress == currentTeam.teamAddress){
                        handleClickConfirmPopOver(event, ConfirmType.REMOVE_MEMBER, currentTeam._id , member.walletAddress, `Are you sure you want to remove "${member.playerName}"?`)}
                      }
                    }
                  >
                    {walletAddress == currentTeam.teamAddress && <ClearIcon />}
                  </IconButton>}
                  {member.walletAddress === currentTeam.teamAddress && <IconButton
                    color="primary"
                    aria-label="reject"
                    size="large"
                  >
                    <Icon
                        icon="mdi:crown"
                        color="#3f51b5"
                        width="24"
                        height="24"
                    />
                  </IconButton>}
                  </Stack>
                </Grid>
              </Grid>
            </StyledPaper>)}
          </Box>
        </Box>}
      </TabPanel>
    </Box>
  );
};

export default ProfileTeam;

import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'SUPA-WFTM',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      4002: '0x4Af94C2abf7867258D3aC691FD863842966C081C',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'SUPA-ETH',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      4002: '0x78Fb56c4953508333F0647b3dD588867A5d3283F',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 2,
    lpSymbol: 'SUPA-USDC',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      4002: '0x64A1EB2b39e200b2A810e3d809CF24728EbdBa5A',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 3,
    lpSymbol: 'SUPA-BOO',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      4002: '0x1f4E7A25adE8487B1BeA5095e596A0b3F8d20adA',
    },
    token: serializedTokens.boo,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 4,
    lpSymbol: 'SUPA-RING',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      4002: '0xa53D4831F26bc75Fa2c83C66CAAB7b741015fe3d',
    },
    token: serializedTokens.ring,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 5,
    lpSymbol: 'SUPA-BEETS',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      4002: '0x87367F5B889e87Bd46D61a9293Aaa0d8aA448aB6',
    },
    token: serializedTokens.beets,
    quoteToken: serializedTokens.cake,
  },

  {
    pid: 6,
    lpSymbol: 'SUPA-FANG',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      4002: '0xEb3eAF8cAeB22e1bBAB4F92707979b03856F380A',
    },
    token: serializedTokens.fang,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 7,
    lpSymbol: 'WFTM-USDC',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      4002: '0x2f197bFC4E3Ba6CDEea88947E8F31EBbe8aB9B32',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
 

  {
    pid: 8,
    lpSymbol: 'WFTM-ETH',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      4002: '0x1ad375F91857274852106d0b3cd388bb38790EDB',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.wbnb,
  },
 
  
 
]

export default farms

import React, {useEffect, useState} from "react";
import { alertVariants, Box, Flex, Skeleton, Text } from "@pancakeswap/uikit";
import { useWeb3React } from "@web3-react/core";
import { FetchStatus, useGetBnbBalance } from "../../../hooks/useTokenBalance";
import { useTranslation } from "../../../contexts/Localization";
import Button from "@mui/material/Button";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CopyAddressPlayer from "./CopyAddressPlayer";
import UserService from "../../../services/UserService";
import TeamService from "../../../services/TeamService";
import ToastContainer from "../../Toast/ToastContainer";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ProfileButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#42eacb',
  color: 'rgba(0, 0, 0, 0.87)',
  '&:hover': {
    backgroundColor: 'rgb(46, 163, 142)',
  },
  '&:disabled': {
    backgroundColor: 'rgba(46, 163, 142, .5)',
    color: 'rgba(0, 0, 0, 0.5)',
  },
}));

interface ProfileInfoProps {
  onDismiss: any;
  walletAddress?: string;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ walletAddress, onDismiss }) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { balance, fetchStatus } = useGetBnbBalance();
  const [profile, setProfile] = useState<any>(null)
  const [pendingRequests, setPendingRequests] = useState([])
  const [friendRequested, setFriendRequested] = useState<any>([])
  const [approvedRequest, setApprovedRequest] = useState<boolean>(false)
  const [isRequested, setIsRequested] = useState<boolean>(false)
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isRemoved, setIsRemoved] = useState<boolean>(false)
  const [currentTeam, setCurrentTeam] = useState<any>(null)
  const [toasts, setToasts] = useState([]);

  const addFriend = async () => {
    setIsRequested(true)
    return await UserService.addFriend(account, {address: profile.walletAddress})
  }

  const approveFriend = async () => {
    setIsApproved(true)
    await UserService.approveFriend(profile.walletAddress, {address: account})
    await fetchUserData(walletAddress)
  }

  const removeFriend = async () => {
    setIsRemoved(true)
    await UserService.removeFriend(profile.walletAddress, {address: account})
    await fetchUserData(walletAddress)
  }

  const getFriendRequest = (friendRequests, walletAddress, isPending = false) => {
    const requests = friendRequests?.filter((friend: any) => friend.walletAddress === walletAddress)
    if (requests && requests.length) {
      setFriendRequested(requests)
      setIsRequested(true)
      setApprovedRequest(!isPending)
    }
  }

  const getUserProfile = async (address) => {
    const userProfile = await UserService.getUserByAddress(address)
    getFriendRequest(userProfile?.friends, account)
    return setProfile(userProfile)
  }

  const loadAllPendingRequests = async (address: string) => {
    const requests = await UserService.getAllPendingFriends(address)
    setPendingRequests(requests ?? [])
    getFriendRequest(requests ?? [], walletAddress, true)
  }

  const fetchUserData = async (address) => {
    await getUserProfile(walletAddress);
    await loadAllPendingRequests(account as any)
  }

  const loadTeam = async (address: string) => {
    const user = await UserService.getUserByAddress(address)
    if(user && user.team){
      const team = await TeamService.getTeamById(user.team)
      setCurrentTeam(team)
    }
  }

  const sendTeamRequest = async (teamId: string, userAddress: string, playerName: string) => {
     await TeamService.inviteUser({userAddress, teamId})
     showNotification("Team request sent", `Please wait for "${playerName}" to accept`)
     if(account) loadTeam(account)
  }

  const showNotification = (title: string, description: string) => {
    const now = Date.now();
    const randomToast = {
      id: `id-${now}`,
      title,
      description,
      type: ""
    } as never;
    setToasts((prevToasts: never[]) => [randomToast, ...prevToasts]);
  };

  const handleRemoveNotification = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((prevToast: any) => prevToast.id !== id));
  };

  useEffect(() => {
    fetchUserData(walletAddress)
  }, [])

  useEffect(() => {
    if(account) loadTeam(account)
  }, [account])

  const isCurrentAccount = Boolean(account === profile?.walletAddress)

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <img
              src={`https://via.placeholder.com/120?w=164&h=164&fit=crop&auto=format`}
              srcSet={`https://via.placeholder.com/120?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={"profile"}
              loading="lazy"
            />
          </Grid>
          <Grid item xs={8}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="textSubtle">{t("Name:")}</Text>
              {fetchStatus !== FetchStatus.SUCCESS ? (
                <Skeleton height="22px" width="60px" />
              ) : (
                <Text>{ profile?.playerName }</Text>
              )}
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="textSubtle">{t("Wallet:")}</Text>
              {fetchStatus !== FetchStatus.SUCCESS ? (
                <Skeleton height="22px" width="60px" />
              ) : (
                <CopyAddressPlayer account={profile?.walletAddress} />
              )}
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="textSubtle">{t("Level:")}</Text>
              {fetchStatus !== FetchStatus.SUCCESS ? (
                <Skeleton height="22px" width="60px" />
              ) : (
                <Text>{ profile?.level || 1 }</Text>
              )}
              <Text color="textSubtle">{t("/XP: ")}</Text>
              {fetchStatus !== FetchStatus.SUCCESS ? (
                <Skeleton height="22px" width="60px" />
              ) : (
                <Text>{ profile?.xp }</Text>
              )}
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="textSubtle">{t("Team")}</Text>
              {fetchStatus !== FetchStatus.SUCCESS ? (
                <Skeleton height="22px" width="60px" />
              ) : (
                <Text>Supa Team</Text>
              )}
            </Flex>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item xs>
            {
              !isCurrentAccount &&
              !approvedRequest &&
              <ProfileButton
                sx={{ marginTop: 2 }}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={isRequested}
                onClick={() => addFriend()}
              >
                Send Friend Request
              </ProfileButton>
            }
            {
              currentTeam && walletAddress !== account && <ProfileButton
                sx={{ marginTop: 2 }}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={currentTeam.teamMembers.some((member) => member.walletAddress === walletAddress)}
                onClick={() => sendTeamRequest(currentTeam._id, walletAddress ?? "", profile.playerName)}
              >
                Send Team Request
              </ProfileButton>
            }
          </Grid>
        </Grid>
      </Box>
      <ToastContainer toasts={toasts} onRemove={handleRemoveNotification} />
    </>
  );
};

export default ProfileInfo;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MerchantState {
  merchantDialogOpen: boolean;
  merchantId: null | string;
  merchantUrl: null | string;
  urls: Map<string, string>;
}

const initialState: MerchantState = {
  merchantDialogOpen: false,
  merchantId: null,
  merchantUrl: null,
  urls: new Map(),
};

export const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    openMerchantDialog: (state, action: PayloadAction<string>) => {
      state.merchantDialogOpen = true;
      state.merchantId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.merchantUrl = url;
    },
    closeMerchantDialog: (state) => {
      state.merchantDialogOpen = false;
      state.merchantId = null;
      state.merchantUrl = null;
    },
  },
});

export const { openMerchantDialog, closeMerchantDialog } = merchantSlice.actions;

export default merchantSlice.reducer;

import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from '../../state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wbnb: new Token(
    MAINNET,
    '0xf1277d1Ed8AD466beddF92ef448A132661956621',
    18,
    'WFTM',
    'Wrapped Fantom',
    'https://www.fantom.foundation',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'FTM' can be used
  bnb: new Token(MAINNET, '0xf1277d1Ed8AD466beddF92ef448A132661956621', 18, 'Fantom', 'FTM', 'https://www.fantom.foundation'),
  cake: new Token(
    MAINNET,
    '0x44125Bc412077886e79cC3638fb7cf1e32701031',
    18,
    'SUPA',
    'SUPA Foundation Token',
    'https://supa.foundation/',
  ),
  busd: new Token(
    MAINNET,
    '0x71aD2d08d92814Cfb20242bC8C0b530b8969c72B',
    18,
    'USDC',
    'USD Coin',
    'https://www.centre.io/usdc',
  ),
  eth: new Token(
    MAINNET,
    '0x97dF0bF35e33fb2f7Be05bbD828B209773886e37',
    18,
    'ETH',
    'PancakeSwap Token',
    'https://ethereum.org',
  ),
  ring: new Token(
    MAINNET,
    '0xcf75E98D78374496205668A60be23aca7aD07b76',
    18,
    'RING',
    'PancakeSwap Token',
    'https://onering.finance',
  ),
  fang: new Token(
    MAINNET,
    '0xA53F2625D22a61442bC01018E684c50e70Fa781a',
    18,
    'FANG',
    'Fang Token',
    'https://potluckprotocol.com/',
  ),
  boo: new Token(
    MAINNET,
    '0x16473653614C56EB8DD6c7dFbec771c52Af6D439',
    18,
    'BOO',
    'SpookySwap Finance',
    'https://spookyswap.finance/',
  ),
  beets: new Token(
    MAINNET,
    '0x0646044A927b2918472Cfb3f9F8eAE51a2421Dc0',
    18,
    'BEETS',
    'BeethovenX',
    'https://beets.fi',
  ),
}

export const testnetTokens = {
  wbnb: new Token(
    TESTNET,
    '0xf1277d1Ed8AD466beddF92ef448A132661956621',
    18,
    'WFTM',
    'Wrapped Fantom',
    'https://www.fantom.foundation',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'FTM' can be used
  bnb: new Token(MAINNET, '0xf1277d1Ed8AD466beddF92ef448A132661956621', 18, 'Fantom', 'FTM', 'https://www.fantom.foundation'),
  cake: new Token(
   TESTNET,
    '0x44125Bc412077886e79cC3638fb7cf1e32701031',
    18,
    'SUPA',
    'SUPA Foundation Token',
    'https://supa.foundation/',
  ),
  busd: new Token(
    TESTNET,
    '0x71aD2d08d92814Cfb20242bC8C0b530b8969c72B',
    18,
    'USDC',
    'USD Coin',
    'https://www.centre.io/usdc',
  ),
 
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId as any, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}
export default tokens()

import React from "react";
import { useWalletModal } from "@pancakeswap/uikit";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "../contexts/Localization";
import Button from "@mui/material/Button";

const ConnectWalletButton = (props) => {
  const { t } = useTranslation();
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(login, logout, t);

  return (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      onClick={onPresentConnectModal}
      {...props}
    >
      {t("Connect Wallet")}
    </Button>
  );
};

export default ConnectWalletButton;

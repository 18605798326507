import Game from "./Game";
import { createCharacterAnims } from "../anims/CharacterAnims";
import "../characters/MyPlayer";
import "../characters/OtherPlayer";
import OtherPlayer from "../characters/OtherPlayer";
import PlayerSelector from "../characters/PlayerSelector";
import Network from "../services/Network";
import store from "../state";
import { setLevel } from "../state/ChatStore";
import MadMarv from "../items/npc/MadMarv";
import Chlamydia from "../items/npc/Chlamydia";
import Stan from "../items/npc/Stan";
import Yukihira from "../items/npc/Yukihira";
import Loopy from "../items/npc/Loopy";
import Merchant from "../items/npc/Merchant";
import SpecimenDealer from "../items/npc/SpecimenDealer";
import TokenTrader from "../items/npc/TokenTrader";
import { NpcMadMarv } from "../gameobjects/NpcMadMarv";
import { NpcChlamydia } from "../gameobjects/NpcChlamydia";
import { NpcStan } from "../gameobjects/NpcStan";
import { NpcYukihira } from "../gameobjects/NpcYukihira";
import { NpcLoopy } from "../gameobjects/NpcLoopy";
import { NpcMerchant } from "../gameobjects/NpcMerchant";
import { NpcSpecimenDealer } from "../gameobjects/NpcSpecimenDealer";
import { NpcTokenTrader } from "../gameobjects/NpcTokenTrader";
import { MAP_CONTENT_KEYS } from "../constants/map-content-keys";
import { MAPS } from "../constants/maps";
import { removeIdList, setLocationz, setPlayerX, setPlayerY } from "../state/UserStore";
import { Levels } from "../constants/levels";
import { SCENES } from "../constants/scenes";

export default class FirstLevel extends Game {
  constructor() {
    super(SCENES.FIRST_LEVEL, MAPS.firstLevel.key);
  }

  protected init(data: any) {
    this.createLayer(data);
    this.physics.world.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels);

    const levelRightCorridor = this.map.objects.find((o) => o.name === MAP_CONTENT_KEYS.objects.ZONES_CORRIDOR_RIGHT);
    if (levelRightCorridor) {
      levelRightCorridor.objects.map((o: any) => {
        const zone = this.add.zone(o.x, o.y, o.width, o.height);
        this.physics.add.existing(zone);
        this.physics.add.overlap(zone, this.playerSelector, () => {
          if (o.properties[3].value === MAP_CONTENT_KEYS.objects.ZONES_CORRIDOR_RIGHT) {
            store.dispatch(setPlayerX(800));
            store.dispatch(setPlayerY(6900));
          }
          this.scene.start(o.properties[1].value, {
            comesFrom: this.scene.key,
            network: this.network,
          });
        });
      });
    }

    const levelLeftCorridor = this.map.objects.find((o) => o.name === MAP_CONTENT_KEYS.objects.ZONES_CORRIDOR_LEFT);
    if (levelLeftCorridor) {
      levelLeftCorridor.objects.map((o: any) => {
        const zone = this.add.zone(o.x, o.y, o.width, o.height);
        this.physics.add.existing(zone);
        this.physics.add.overlap(zone, this.playerSelector, () => {
          if (o.properties[3].value === MAP_CONTENT_KEYS.objects.ZONES_CORRIDOR_LEFT) {
            store.dispatch(setPlayerX(18500));
            store.dispatch(setPlayerY(4300));
          }
          this.scene.start(o.properties[1].value, {
            comesFrom: this.scene.key,
            network: this.network,
          });
        });
      });
    }
    // this.createAudio();
    this.initCamera();
    this.registerKeys();
  }

  createLayer(data: { network: Network }) {
    if (!data.network) {
      throw new Error("server instance missing");
    } else {
      this.network = data.network;
    }

    store.dispatch(removeIdList());
    store.dispatch(setLocationz(1));

    createCharacterAnims(this.anims);

    this.map = this.make.tilemap({ key: this.mapKey });
    store.dispatch(setLevel(SCENES.FIRST_LEVEL));

    const dataState = store.getState().userGame;
    this.myPlayer = this.add.myPlayer(
      dataState.playerX ? dataState.playerX : 15600,
      dataState.playerY ? dataState.playerY : 2000,
      dataState.textureName ? dataState.textureName : "male",
      this.network.mySessionId
    );

    Levels.firstLevel.layers.map((layer) => {
      const tilesetImage = this.map.addTilesetImage(layer.tilesetImage.tilesetName, layer.tilesetImage.tilesetKey);

      const newLayer = this.map.createLayer(layer.layerName, tilesetImage);
      newLayer.setCollisionByProperty({ collides: layer.collision });
      this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], newLayer);
    });

    this.playerSelector = new PlayerSelector(this, 0, 0, 256, 512);

    // don't go out of the map
    this.physics.world.bounds.width = this.map.widthInPixels;
    this.physics.world.bounds.height = this.map.heightInPixels;
    this.myPlayer.setCollideWorldBounds(true);

    // ---- NPC ----
    const madMarvs = this.physics.add.staticGroup({
      classType: MadMarv,
    });
    const chlamydias = this.physics.add.staticGroup({
      classType: Chlamydia,
    });
    const stans = this.physics.add.staticGroup({
      classType: Stan,
    });
    const yukihiras = this.physics.add.staticGroup({
      classType: Yukihira,
    });
    const loopys = this.physics.add.staticGroup({
      classType: Loopy,
    });
    const merchants = this.physics.add.staticGroup({
      classType: Merchant,
    });
    const specimenDealers = this.physics.add.staticGroup({
      classType: SpecimenDealer,
    });
    const tokenTraders = this.physics.add.staticGroup({
      classType: TokenTrader,
    });

    const specimenDealerLayer = this.map.getObjectLayer("NPC");
    specimenDealerLayer.objects.forEach((obj, i) => {
      if (obj.name === "MadMarv") {
        const item = this.addObjectFromPoint(madMarvs, obj, "madmarv", "MadMarv") as MadMarv;
        const id = `${i}`;
        item.id = id;
        this.madMarvMap.set(id, item);
      }
      if (obj.name === "Chlamydia") {
        const item = this.addObjectFromPoint(chlamydias, obj, "chlamydia", "Chlamydia") as Chlamydia;
        const id = `${i}`;
        item.id = id;
        this.chlamydiaMap.set(id, item);
      }
      if (obj.name === "Stan") {
        const item = this.addObjectFromPoint(stans, obj, "stan", "Stan") as Stan;
        const id = `${i}`;
        item.id = id;
        this.stanMap.set(id, item);
      }
      if (obj.name === "Yukihira") {
        const item = this.addObjectFromPoint(yukihiras, obj, "yukihira", "Yukihira") as Yukihira;
        const id = `${i}`;
        item.id = id;
        this.yukihiraMap.set(id, item);
      }
      if (obj.name === "Loopy") {
        const item = this.addObjectFromPoint(yukihiras, obj, "loopy", "Loopy") as Loopy;
        const id = `${i}`;
        item.id = id;
        this.loopyMap.set(id, item);
      }
      if (obj.name === "Merchant") {
        const item = this.addObjectFromPoint(merchants, obj, "merchant", "Merchant") as Merchant;
        const id = `${i}`;
        item.id = id;
        this.merchantMap.set(id, item);
      }
      if (obj.name === "SpecimenDealer") {
        const item = this.addObjectFromPoint(specimenDealers, obj, "specimendealer", "SpecimenDealer") as SpecimenDealer;
        const id = `${i}`;
        item.id = id;
        this.specimenDealerMap.set(id, item);
      }
      if (obj.name === "TokenTrader") {
        const item = this.addObjectFromPoint(tokenTraders, obj, "tokentrader", "TokenTrader") as TokenTrader;
        const id = `${i}`;
        item.id = id;
        this.tokenTraderMap.set(id, item);
      }
    });

    this.npcSpecimenDealer.map((npc: NpcSpecimenDealer) => {
      this.physics.add.collider(npc, this.myPlayer, npc.talk);
    });

    this.npcTokenTrader.map((npc: NpcTokenTrader) => {
      this.physics.add.collider(npc, this.myPlayer, npc.talk);
    });

    this.physics.add.overlap(this.playerSelector, [specimenDealers, tokenTraders, madMarvs, chlamydias, stans, yukihiras, loopys, merchants], this.handleItemSelectorOverlap, undefined, this);
    // [specimenDealers, tokenTraders, etc],
    // ---- NPC ----

    // import other objects from Tiled map to Phaser -> use object
    Levels.firstLevel.tiles.map((tile) => {
      this.addGroupFromTiled(tile.objectLayerName, tile.key, tile.tilesetName, tile.collidable);
    });

    this.otherPlayers = this.physics.add.group({ classType: OtherPlayer });
    for (const key in store.getState().userGame.playerLocMap) {
      console.log(store.getState().userGame.playerLocMap[key], "LOG");
      if (store.getState().userGame.playerLocMap[key].locationz == store.getState().userGame.locationz) {
        const otherPlayer = this.add.otherPlayer(
          store.getState().userGame.playerLocMap[key].x,
          store.getState().userGame.playerLocMap[key].y,
          store.getState().userGame.playerLocMap[key].anim.split("_")[0] ? store.getState().userGame.playerLocMap[key].anim.split("_")[0] : "male",
          store.getState().userGame.playerLocMap[key].id,
          store.getState().userGame.playerLocMap[key].name
        );
        this.otherPlayers.add(otherPlayer);
        this.otherPlayerMap.set(store.getState().userGame.playerLocMap[key].id, otherPlayer);
      }
    }

    this.physics.add.overlap(this.myPlayer, this.otherPlayers, this.handlePlayersOverlap, undefined, this);

    // register network event listeners
    this.network.onPlayerJoinedGame(this.handlePlayerJoined, this);
    this.network.onPlayerJoinedScene1(this.handlePlayerJoined, this);

    this.network.onPlayerLeft(this.handlePlayerLeft, this);
    this.network.onMyPlayerReady(this.handleMyPlayerReady, this);
    this.network.onMyPlayerVideoConnected(this.handleMyVideoConnected, this);
    this.network.onPlayerUpdated1(this.handlePlayerUpdated, this);
    this.network.onItemUserAdded(this.handleItemUserAdded, this);
    this.network.onItemUserRemoved(this.handleItemUserRemoved, this);
    this.network.onChatMessageAdded(this.handleChatMessageAdded, this);
  }
}

import Phaser from "phaser";
import Item from "../items/Item";
import Computer from "../items/Computer";
import Whiteboard from "../items/Whiteboard";
import Chlamydia from "../items/npc/Chlamydia";
import Engineer from "../items/npc/Engineer";
import HydroponicScientist from "../items/npc/HydroponicScientist";
import LabScientist from "../items/npc/LabScientist";
import Loopy from "../items/npc/Loopy";
import MadMarv from "../items/npc/MadMarv";
import Merchant from "../items/npc/Merchant";
import Microbiologist from "../items/npc/Microbiologist";
import SpecimenDealer from "../items/npc/SpecimenDealer";
import Stan from "../items/npc/Stan";
import TokenTrader from "../items/npc/TokenTrader";
import Yukihira from "../items/npc/Yukihira";
import "../characters/MyPlayer";
import "../characters/OtherPlayer";
import MyPlayer from "../characters/MyPlayer";
import OtherPlayer from "../characters/OtherPlayer";
import Network from "../services/Network";
import { IPlayer } from "../../../types/IOfficeState";
import { PlayerBehavior } from "../../../types/PlayerBehavior";
import { ItemType } from "../../../types/Items";
import store from "../state";
import { setFocused, setShowChat, setLevel } from "../state/ChatStore";
import { NpcChlamydia } from "../gameobjects/NpcChlamydia";
import { NpcEngineer } from "../gameobjects/NpcEngineer";
import { NpcHydroponicScientist } from "../gameobjects/NpcHydroponicScientist";
import { NpcLabScientist } from "../gameobjects/NpcLabScientist";
import { NpcLoopy } from "../gameobjects/NpcLoopy";
import { NpcMadMarv } from "../gameobjects/NpcMadMarv";
import { NpcMerchant } from "../gameobjects/NpcMerchant";
import { NpcMicrobiologist } from "./../gameobjects/NpcMicrobiologist";
import { NpcSpecimenDealer } from "../gameobjects/NpcSpecimenDealer";
import { NpcStan } from "../gameobjects/NpcStan";
import { NpcTokenTrader } from "../gameobjects/NpcTokenTrader";
import { NpcYukihira } from "../gameobjects/NpcYukihira";
import { removeIdOne, setIdList } from "../state/UserStore";
export default class Game extends Phaser.Scene {
  network!: Network;
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  private keyE!: Phaser.Input.Keyboard.Key;
  private keyR!: Phaser.Input.Keyboard.Key;
  protected map!: Phaser.Tilemaps.Tilemap;
  myPlayer!: MyPlayer;
  protected playerSelector!: Phaser.GameObjects.Zone;
  protected otherPlayers!: Phaser.Physics.Arcade.Group;
  protected otherPlayerMap = new Map<string, OtherPlayer>();
  computerMap = new Map<string, Computer>();
  private whiteboardMap = new Map<string, Whiteboard>();
  public npcChlamydia: NpcChlamydia[];
  public npcEngineer: NpcEngineer[];
  public npcHydroponicScientist: NpcHydroponicScientist[];
  public npcLabScientist: NpcLabScientist[];
  public npcLoopy: NpcLoopy[];
  public npcMadMarv: NpcMadMarv[];
  public npcMerchant: NpcMerchant[];
  public npcMicrobiologist: NpcMicrobiologist[];
  public npcSpecimenDealer: NpcSpecimenDealer[];
  public npcStan: NpcStan[];
  public npcTokenTrader: NpcTokenTrader[];
  public npcYukihira: NpcYukihira[];
  protected chlamydiaMap = new Map<string, Chlamydia>();
  protected engineerMap = new Map<string, Engineer>();
  protected hydroponicScientistMap = new Map<string, HydroponicScientist>();
  protected labScientistMap = new Map<string, LabScientist>();
  protected loopyMap = new Map<string, Loopy>();
  protected madMarvMap = new Map<string, MadMarv>();
  protected merchantMap = new Map<string, Merchant>();
  protected microBiologistsMap = new Map<string, Microbiologist>();
  protected specimenDealerMap = new Map<string, SpecimenDealer>();
  protected stanMap = new Map<string, Stan>();
  protected tokenTraderMap = new Map<string, TokenTrader>();
  protected yukihiraMap = new Map<string, Yukihira>();
  public mapKey: string;

  constructor(key: string, mapKey: string) {
    super(key);
    this.mapKey = mapKey;
    this.npcChlamydia = [];
    this.npcEngineer = [];
    this.npcHydroponicScientist = [];
    this.npcLabScientist = [];
    this.npcLoopy = [];
    this.npcMadMarv = [];
    this.npcMerchant = [];
    this.npcMicrobiologist = [];
    this.npcSpecimenDealer = [];
    this.npcStan = [];
    this.npcTokenTrader = [];
    this.npcYukihira = [];
  }

  registerKeys() {
    this.cursors = this.input.keyboard.createCursorKeys();
    // maybe we can have a dedicated method for adding keys if more keys are needed in the future
    this.keyE = this.input.keyboard.addKey("E");
    this.keyR = this.input.keyboard.addKey("R");
    this.input.keyboard.disableGlobalCapture();
    this.input.keyboard.on("keydown-ENTER", (event) => {
      store.dispatch(setShowChat(true));
      store.dispatch(setFocused(true));
    });
    this.input.keyboard.on("keydown-ESC", (event) => {
      store.dispatch(setShowChat(false));
    });
  }

  disableKeys() {
    this.input.keyboard.enabled = false;
  }

  enableKeys() {
    this.input.keyboard.enabled = true;
  }

  createAudio() {
    this.sound.add("walk", { loop: true });
  }

  handleCollissionOutMap(this: any) {
    this.physics.world.bounds.width = this.map.widthInPixels;
    this.physics.world.bounds.height = this.map.heightInPixels;
    this.myPlayer.setCollideWorldBounds(true);
  }

  protected handleItemSelectorOverlap(playerSelector, selectionItem) {
    const currentItem = playerSelector.selectedItem as Item;
    // currentItem is undefined if nothing was perviously selected
    if (currentItem) {
      // if the selection has not changed, do nothing
      if (currentItem === selectionItem || currentItem.depth >= selectionItem.depth) {
        return;
      }
      // if selection changes, clear pervious dialog
      if (this.myPlayer.playerBehavior !== PlayerBehavior.SITTING) currentItem.clearDialogBox();
    }

    // set selected item and set up new dialog
    playerSelector.selectedItem = selectionItem;
    selectionItem.onOverlapDialog();
  }

  protected addObjectFromPoint(group: Phaser.Physics.Arcade.StaticGroup, object: Phaser.Types.Tilemaps.TiledObject, key: string, tilesetName: string) {
    const actualX = object.x! + object.width! * 0.5;
    const actualY = object.y! - object.height! * 0.5;
    const obj = group.get(actualX, actualY, key, object.gid!).setDepth(actualY);
    return obj;
  }

  protected addObjectFromTiled(group: Phaser.Physics.Arcade.StaticGroup, object: Phaser.Types.Tilemaps.TiledObject, key: string, tilesetName: string) {
    const actualX = object.x! + object.width! * 0.5;
    const actualY = object.y! - object.height! * 0.5;
    const obj = group.get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid).setDepth(actualY);
    return obj;
  }

  protected addGroupFromTiled(objectLayerName: string, key: string, tilesetName: string, collidable: boolean) {
    const group = this.physics.add.staticGroup();
    const objectLayer = this.map.getObjectLayer(objectLayerName);
    objectLayer.objects.forEach((object) => {
      const actualX = object.x! + object.width! * 0.5;
      let actualY = object.y! - object.height! * 0.5;
      if (objectLayerName === "ObjectWallTilesConferenceRoom" && actualY < 3000) {
        group.get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid).setDepth(3000);
      } else if (objectLayerName === "ObjectMarketplaceWallTiles" && actualY < 1500) {
        group.get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid).setDepth(1500);
      } else if (objectLayerName === "ObjectWall" && actualY < 1550) {
        group.get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid).setDepth(1700);
      } else if (objectLayerName === "ObjectWall" && actualY < 3300 && actualY > 2900) {
        group.get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid).setDepth(3300);
      } else if (objectLayerName === "ObjectWall" && actualY < 3800 && actualY > 3400) {
        group.get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid).setDepth(3800);
      } else if (objectLayerName === "ObjectWall" && actualY < 5300 && actualY > 4900) {
        group.get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid).setDepth(5300);
      } else {
        group.get(actualX, actualY, key, object.gid! - this.map.getTileset(tilesetName).firstgid).setDepth(actualY);
      }
    });
    if (this.myPlayer && collidable) this.physics.add.collider([this.myPlayer, this.myPlayer.playerContainer], group);
  }

  // function to add new player to the otherPlayer group
  protected handlePlayerJoined(newPlayer: IPlayer, id: string) {
    if (!store.getState().userGame.idList.includes(id)) {
      store.dispatch(setIdList(id));
      const dataState = store.getState().userGame;
      const otherPlayer = this.add.otherPlayer(newPlayer.x, newPlayer.y, dataState.textureName ? dataState.textureName : "male", id, newPlayer.name);
      this.otherPlayers.add(otherPlayer);
      this.otherPlayerMap.set(id, otherPlayer);
    }
  }

  // function to remove the player who left from the otherPlayer group
  protected handlePlayerLeft(id: string) {
    if (this.otherPlayerMap.has(id)) {
      const otherPlayer = this.otherPlayerMap.get(id);
      otherPlayer?.setVisible(false).setActive(false);
      store.dispatch(removeIdOne(id));

      if (!otherPlayer) return;
      try {
        this.otherPlayers.remove(otherPlayer, true, true);
      } catch {}
      this.otherPlayerMap.delete(id);
    }
  }

  protected handleMyPlayerReady() {
    this.myPlayer.readyToConnect = true;
  }

  protected handleMyVideoConnected() {
    this.myPlayer.videoConnected = true;
  }

  // function to update target position upon receiving player updates
  protected handlePlayerUpdated(field: string, value: number | string, id: string) {
    const otherPlayer = this.otherPlayerMap.get(id);
    otherPlayer?.updateOtherPlayer(field, value);
    if (store.getState().userGame.playerLocMap[id].locationz != store.getState().userGame.locationz) {
      otherPlayer?.setVisible(false).setActive(false);
      if (!otherPlayer) return;

      this.otherPlayers.remove(otherPlayer, true, true);
      this.otherPlayerMap.delete(id);
    }
  }

  protected handlePlayersOverlap(myPlayer, otherPlayer) {
    otherPlayer.makeCall(myPlayer, this.network?.webRTC);
  }

  protected handleItemUserAdded(playerId: string, itemId: string, itemType: ItemType) {
    if (itemType === ItemType.COMPUTER) {
      const computer = this.computerMap.get(itemId);
      computer?.addCurrentUser(playerId);
    } else if (itemType === ItemType.WHITEBOARD) {
      const whiteboard = this.whiteboardMap.get(itemId);
      whiteboard?.addCurrentUser(playerId);
    }
  }

  protected handleItemUserRemoved(playerId: string, itemId: string, itemType: ItemType) {
    if (itemType === ItemType.COMPUTER) {
      const computer = this.computerMap.get(itemId);
      computer?.removeCurrentUser(playerId);
    } else if (itemType === ItemType.WHITEBOARD) {
      const whiteboard = this.whiteboardMap.get(itemId);
      whiteboard?.removeCurrentUser(playerId);
    }
  }

  protected handleChatMessageAdded(playerId: string, content: string) {
    const otherPlayer = this.otherPlayerMap.get(playerId);
    otherPlayer?.updateDialogBubble(content);
  }

  update(t: number, dt: number) {
    if (this.myPlayer && this.network) {
      this.playerSelector.update(this.myPlayer, this.cursors);
      this.myPlayer.update(this.playerSelector, this.cursors, this.keyE, this.keyR, this.network);
    }
  }

  protected initCamera() {
    this.cameras.main.zoom = 0.256;
    this.cameras.main.startFollow(this.myPlayer, true);
    this.cameras.main.roundPixels = true;
  }
}

import { CONFIG_API_SERVICE_AUTH } from "./../config/index";
import axios from "axios";

export async function postLogin(data: any) {
  const { data: response } = await axios.post(
    `http://localhost:3002/api/v1/auth/login`,
    data
  );
  return response;
}

export async function verifyLogin(data: any) {
  const { data: response } = await axios.post(
    `http://localhost:3002/api/v1/auth/verified`,
    data
  );
  return response;
}

export function saveTokenInLocalStorage(token: string) {
  localStorage.setItem("token", token);
}

export async function checkAutoLogin(account: string, condition: boolean) {
  const token = condition ? localStorage.getItem("token") : "";

  const { data: response } = await axios.post(
    `http://localhost:3002/api/v1/auth/login`,
    {
      playerName: "",
      playerTexture: "",
      walletAddress: account,
      signature: "",
      message: "",
      token,
    }
  );

  if (response.status) {
    saveTokenInLocalStorage(response.data.token);
  } else {
    saveTokenInLocalStorage("");
  }
}

export function getTokenInLocalStorage() {
  const data = localStorage.getItem("token");
  if (data) {
    const decodedJwt = JSON.parse(atob(data.split(".")[1]));
    if (decodedJwt.exp * 1000 < Date.now()) {
      // code logout
      removeTokenInLocalStorage();
    } else {
      // code not expired
      return decodedJwt;
    }
  }
  if (!data) {
    return false;
  }
}

export function removeTokenInLocalStorage() {
  console.log("signout");
  localStorage.removeItem("token");
  setTimeout(() => {
    window.location.reload();
  });
}

import React from "react";
import { useWeb3React } from "@web3-react/core";
import {
  Flex,
  AccountIcon,
  LogoutIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
} from "@pancakeswap/uikit";
import useAuth from "../../../hooks/useAuth";
import ConnectWalletButton from "../../../dex-components/ConnectWalletButton";
import { FetchStatus, useGetBnbBalance } from "../../../hooks/useTokenBalance";
import { useTranslation } from "../../../contexts/Localization";
import WalletModal, { WalletView, LOW_BNB_BALANCE } from "./WalletModal";
import WalletUserMenuItem from "./WalletUserMenuItem";
import { removeTokenInLocalStorage } from "../../../services/AuthService";
import ProfileSummaryModal, { ProfileSummaryView } from "./ProfileSummaryModal";

const UserMenu = () => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { logout } = useAuth();
  const { balance, fetchStatus } = useGetBnbBalance();
  const [onPresentWalletModal] = useModal(
    <WalletModal initialView={WalletView.WALLET_INFO} />
  );
  const [onPresentTransactionModal] = useModal(
    <WalletModal initialView={WalletView.TRANSACTIONS} />
  );
  const [onPresentProfileSummaryModal] = useModal(
    <ProfileSummaryModal initialView={ProfileSummaryView.SUMMARY} walletAddress={account as any} />, true, true, "modal_in_index"
  );

  const hasLowbnbBalance =
    fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE);

  if (!account) {
    return <ConnectWalletButton scale="sm" />;
  }

  const handleLogout = () => {
    logout();
    removeTokenInLocalStorage();
  };

  return (
    <>
      <UIKitUserMenu account={account}>
        <WalletUserMenuItem
          hasLowbnbBalance={hasLowbnbBalance}
          onPresentWalletModal={onPresentWalletModal}
        />
        <UserMenuItem as="button" onClick={onPresentTransactionModal}>
          {t("Transactions")}
        </UserMenuItem>
        <UserMenuDivider />

        <UserMenuDivider />
        <UserMenuItem as="button" onClick={handleLogout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t("Disconnect")}
            <LogoutIcon />
          </Flex>
        </UserMenuItem>
      </UIKitUserMenu>
      <UserMenuItem as="button" onClick={onPresentProfileSummaryModal}>
        <AccountIcon />
        &nbsp;{t("Profile")}
      </UserMenuItem>
    </>
  );
};

export default UserMenu;

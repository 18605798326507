import React, { useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useAppSelector } from "../hooks";
import Level from "../utils/level";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export default function VideoConnectionDialog() {
  const [connectionWarning, setConnectionWarning] = useState(true);
  const levelName = useAppSelector((state) => state.chat.levelName);
  const currentLevel = Level.getLevelByName(levelName)

  return (
    <Backdrop>
      <Wrapper>
        {connectionWarning && (
          <Alert
            severity="warning"
            onClose={() => {
              setConnectionWarning(!connectionWarning);
            }}
          >
          <AlertTitle>Notice</AlertTitle>
                No webcam/mic connected -{" "}
                <strong>connect one for best experience!</strong>
              </Alert>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if(currentLevel){
              currentLevel.network.webRTC?.getUserMedia();
            }
          }}
        >
                Enable audio/video conferencing
        </Button>
      </Wrapper>
    </Backdrop>
  );
}

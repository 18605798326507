import React, { useRef, useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CloseIcon from "@mui/icons-material/Close";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { getColorByString } from "../util";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setTeams, Message, MessageType, pushTeamMessage, setActivedTeam, setIsCreateTeam, setIsDeleteTeam, setIsApproveInvitation, setIsLeaveTeam, setRemovedMember, setFocused, setShowChat, addChatFriend, pushFriendMessage, setActivedChatFriend, Friend, Team, setChatFriendRequest, showChatFriend } from "../state/ChatStore";
import Level from "../utils/level";
import { io } from "socket.io-client";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";
import Modal from "@mui/material/Modal";
import { Flex, Skeleton, Text, useModal } from "@pancakeswap/uikit";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CopyAddressOtherPlayer from "../dex-components/Menu/UserMenu/CopyAddressOtherPlayer";
import ChatIcon from "@mui/icons-material/Chat";
import TeamService from "../services/TeamService";
import TeamMessageService from "../services/TeamMessageService";
import { useWeb3React } from "@web3-react/core";
import UserService from "../services/UserService";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProfileSummaryModal, {ProfileSummaryView} from "../dex-components/Menu/UserMenu/ProfileSummaryModal";

const Backdrop = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 400px;
  width: 350px;
  max-height: 50%;
  max-width: 50%;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const FabWrapper = styled.div`
  margin-top: auto;
`;

const ChatHeader = styled.div`
  position: relative;
  height: 30px;
  background: #000000a7;
  border-radius: 10px 10px 0px 0px;
  display: grid;
  grid-template-columns: auto auto auto;
  .grid-item {
    color: #fff;
    border-top-left-radius: 10px 10px;
    border-top-right-radius: 10px 10px;
    padding: 2px 20px 2px 20px;
    font-size: 14px;
    text-align: center;
  }
  .grid-item:hover {
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.8);
    cursor: pointer;
    background-color: #42eacb;
  }
  .active {
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.8);
    background-color: #42eacb;
  }
  h3 {
    color: #fff;
    margin: 7px;
    font-size: 17px;
    text-align: center;
  }
  listMenuChat {
    display: inline-block;
  }
  div.Scrollmenu {
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
  }
  div.Scrollmenu div {
    display: inline-block;
    padding: 2px 20px 2px 20px;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
  }
  .back {
    position: absolute;
    top: 0;
    left: 0;
  }
  .next {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 35px;
  }
`;

const ChatBox = styled(Box)`
  height: 100%;
  width: 100%;
  overflow: auto;
  background: #2c2c2c;
  border: 1px solid #00000029;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 2px;
  p {
    margin: 3px;
    text-shadow: 0.3px 0.3px black;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.4;
    overflow-wrap: anywhere;
  }
  span {
    color: white;
    font-weight: normal;
  }
  .notification {
    color: grey;
    font-weight: normal;
  }
  :hover {
    background: #3a3a3a;
  }
`;

const InputWrapper = styled.form`
  box-shadow: 10px 10px 10px #00000018;
  border: 1px solid #42eacb;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #000000c1, #242424c0);
`;

const InputTextField = styled(InputBase)`
  border-radius: 0px 0px 10px 10px;
  input {
    padding: 5px;
  }
`;

const EmojiPickerWrapper = styled.div`
  position: absolute;
  bottom: 54px;
  right: 16px;
`;

const PopOverOtherPlayer = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  color: "#fff",
};

const ContentChat = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .grid-item {
    font-size: 14px;
  }
  .author {
    cursor: pointer;
  }
  .content {
    color: #fff;
  }
`;

const dateFormatter = new Intl.DateTimeFormat("en", {
  timeStyle: "short",
  dateStyle: "short",
});

interface MessageUIProps {
  message: Message, 
  onOpenProfileSummaryModal: () => void
}

const MessageUI = (props: MessageUIProps) => {
  const { account } = useWeb3React();
  const {message, onOpenProfileSummaryModal} = props

  return (
    <MessageWrapper>
      <Tooltip
        title={dateFormatter.format(message.createdAt)}
        placement="right"
        arrow
      >
        {message.messageType === MessageType.REGULAR_MESSAGE ? (
          <ContentChat>
            <div className="grid-container">
              <p
                className="grid-item author"
                style={{
                  color: getColorByString(message.playerName),
                }}
                onClick={() => {
                  if(account !== message.walletAddress) onOpenProfileSummaryModal()
                }}
              >
                 {message.playerName}:
              </p>
              <p className="grid-item content">{message.content}</p>
            </div>
          </ContentChat>
        ) : (
          <p className="notification">
            {message.playerName} {message.content}
          </p>
        )}
      </Tooltip>
    </MessageWrapper>
  );
};

const CHAT_SERVER_URL = "ws://localhost:3002"

let socket = io(CHAT_SERVER_URL);

export default function Chat() {
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const {showChat, focused, teams, activedTeam, isCreateTeam, removedMember, isApproveInvitation, isDeleteTeam, isLeaveTeam, chatFriends, activedChatFriend, chatFriendRequest, levelName} = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();
  const currentLevel = Level.getLevelByName(levelName);
  const [openOthePlayerProfile, setOpenOthePlayerProfile] =
    React.useState(false);
  const { namePlayer } = useAppSelector((state) => state.userGame);
  const { account } = useWeb3React();
  const [user, setUser] = useState<any>()
  const scrollElement = useRef<HTMLDivElement>(null);
  const activedTeamRef = useRef<any>(null);
  const activedChatFriendRef = useRef<any>(null);
  const [friends, setFriends] = useState([])
  const [friendAddressSearch, setFriendAddressSearch] = useState("")
  const [selectedWalletAddress, setSelectedWalletAddress] = useState<any>(null)


  const handleChangeMessage = (event: React.FormEvent<HTMLInputElement>) => {
    setMessage(event.currentTarget.value);
  };

  const handleChangeFriendAddressSearch = (event: React.FormEvent<HTMLInputElement>) => {
    setFriendAddressSearch(event.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      // move focus back to the game
      inputRef.current?.blur();
      dispatch(setShowChat(false));
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => messagesEndRef.current?.scrollIntoView({ behavior: "smooth" }), 500)
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // move focus back to the game
    inputRef.current?.blur();

    const val = message.trim();
    setMessage("");
    if (currentLevel) {
      const message = { messageType: MessageType.REGULAR_MESSAGE, playerName: namePlayer, walletAddress: account, createdAt: new Date().getTime(), content: val} 
      if(activedTeam) sendMessage('send_team_message', activedTeam.channel, message)
      else if(activedChatFriend) sendMessage("send_friend_message", activedChatFriend.channel, message)
      currentLevel.myPlayer.updateDialogBubble(val);
    }
  };

  const setupTeam = async (keepWorldData: boolean, isFirstTimeJoinTeam: boolean) => {
    let _teams = [{id: null, teamName: "world", teamAddress: "", channel: "world", messages: []}] as any
    if(keepWorldData) _teams = teams ? [teams[0]] : [_teams[0]]
    let _activedTeam = _teams[0];

    if(account){
      const data = await TeamService.getAllTeamsByUserAddress(account, true)
      if(data){
        _teams = _teams.concat(data.map((t: any) => ({id: t._id, teamName: t.teamName, teamAddress: t.teamAddress, channel: `team:${t.teamAddress}`, messages: []})))
      }
      dispatch(setTeams(_teams))

      const user = await UserService.getUserByAddress(account)
      
      if(user){
        setUser(user)
        _teams.forEach((team) => { 
            if(team.teamAddress == user.teamAddress){
              _activedTeam = team
            }
        })
        activedTeamRef.current = _activedTeam
        setFriends(user.friends ?? [])
        dispatch(setActivedTeam(_activedTeam))

        if(!keepWorldData && user.teamAddress){
          const messages = await TeamMessageService.getMessagesByTeam(user.teamAddress)
          if(messages){
            dispatch(pushTeamMessage({channel: _activedTeam.channel, messages}))
            scrollToBottom()
          }
        }
      }else{
        activedTeamRef.current = _activedTeam
        dispatch(setActivedTeam(_activedTeam))
      }
    }
    
    _teams.forEach(team => {
       sendMessage("join", team.channel, {isFirstTimeJoinTeam: team.channel !== "world" ? isFirstTimeJoinTeam : false})
    });
  }

  const showNotification = (message) => {
    console.log(message);
  };

  const sendMessage = (action: string, channel: string, message?: any) => {
    let token = localStorage.getItem("token");
    if(message){
      socket.emit(action, {channel, token, message}, showNotification)
    }else{
      socket.emit(action, {channel, token}, showNotification)
    }
  }

  const handleJoinOrLeftCallback = (playerName: string, team, type: MessageType, content: string) => {
    if(team.channel !== "world"){
      if(playerName === namePlayer) 
      {
         TeamMessageService.createTeamMessage({
            teamAddress: team.teamAddress,
            messageType: type, 
            walletAddress: account ?? "",
            playerName, 
            content, 
            createdAt: new Date().getTime()
          })
      }else{
          dispatch(pushTeamMessage({
            channel: team.channel,
            messages: [{
              messageType: type, 
              walletAddress: account ?? "",
              playerName, 
              content, 
              createdAt: new Date().getTime()
            }]
          }));
          scrollToBottom()
          inputRef.current?.focus();
      } 
    }
  }

  const listenSocketEvent = () => {
    sendMessage("join", "general_message")
    socket.on('team_message', ({message, channel}) => {
      dispatch(pushTeamMessage({channel, messages: [message]}));
      if(activedTeamRef.current.channel === channel) scrollToBottom()
      inputRef.current?.focus();
    })
    socket.on("join", ({playerName, channel}) => {
      if(channel === activedTeamRef.current.channel){
        handleJoinOrLeftCallback(playerName, activedTeamRef.current, MessageType.PLAYER_JOINED, "joined")
      }
    })
    socket.on("left_team", ({playerName, channel}) => {
      if(channel === activedTeamRef.current.channel){
        handleJoinOrLeftCallback(playerName, activedTeamRef.current, MessageType.PLAYER_LEFT, "left")
      }
    })
    socket.on("remove_team_member", ({memberAddress, channel}) => {
      if(channel === activedTeamRef.current.channel){
        if(memberAddress === account) setTimeout(() => setupTeam(true, false), 1000)
      }
    })
    socket.on("delete_team", () => {
      setupTeam(true, false);
    })
    socket.on("general_message", ({message: {senderAddress, receiverAddress, receiverName, senderName}}) => {
        const friendChannel = `dm:${senderAddress}:${receiverAddress}`
        if(receiverAddress === account){
          sendMessage("join", friendChannel)
          const chatfriend = {
            playerName: senderName,
            walletAddress: senderAddress,
            channel: friendChannel,
            messages: [],
            isShow: false
          }
          dispatch(addChatFriend(chatfriend))
        }else if(senderAddress === account){
          sendMessage("join", friendChannel)
          const chatfriend = {
            playerName: receiverName,
            walletAddress: senderAddress,
            channel: friendChannel,
            messages: [],
            isShow: true
          }
          dispatch(addChatFriend(chatfriend))
          dispatch(setActivedTeam(null))
        }
    })
    socket.on("friend_message", ({message, channel}) => {
      dispatch(showChatFriend(channel))
      dispatch(pushFriendMessage({channel, messages: [message]}));
      scrollToBottom()
      inputRef.current?.focus();
    })
  }

  const handleSelectTeamTab = async (team) => {
    dispatch(setActivedTeam(team))
    dispatch(setActivedChatFriend(null))
    activedTeamRef.current = team
    scrollToBottom()
    inputRef.current?.focus();
  }

  const handleSelectFriendTab = async (friend) => {
    dispatch(setActivedChatFriend(friend))
    dispatch(setActivedTeam(null))
    scrollToBottom()
    inputRef.current?.focus();
  }

  useEffect(() => {
    activedChatFriendRef.current = activedChatFriend
  }, [activedChatFriend]);

  useEffect(() => {
    if(account){
      let token = localStorage.getItem("token");
      const interval = setInterval(() => {
        if(token) {
          clearInterval(interval);
          setupTeam(false, false);
          listenSocketEvent()
        }else{
          token = localStorage.getItem("token");
        }
      }, 500)
    }
  }, [account]);
  
  useEffect(() => {
    if (isCreateTeam) {
      setupTeam(true, true);
      dispatch(setIsCreateTeam(false))
    }
  }, [isCreateTeam]);

  useEffect(() => {
    if (removedMember) {
      sendMessage('remove_team_member', activedTeamRef.current.channel, {memberAddress: removedMember})
      dispatch(setRemovedMember(""))
    }
  }, [removedMember]);

  useEffect(() => {
    if (isDeleteTeam) {
      sendMessage('delete_team', activedTeamRef.current.channel)
      setupTeam(true, false);
      dispatch(setIsDeleteTeam(false))
    }
  }, [isDeleteTeam]);

  useEffect(() => {
    if (isApproveInvitation) {
      setupTeam(true, true);
      dispatch(setIsApproveInvitation(false))
    }
  }, [isApproveInvitation]);
  
  useEffect(() => {
    if (isLeaveTeam) {
      sendMessage('left_team', activedTeamRef.current.channel)
      setupTeam(true, false);
      dispatch(setIsLeaveTeam(false))
    }
  }, [isLeaveTeam]);

  useEffect(() => {
    if (chatFriendRequest) {
      sendMessage('send_connect_to_friend', "general_message", chatFriendRequest)
      dispatch(setChatFriendRequest(null))
    }
  }, [chatFriendRequest]);

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus();
    }
  }, [focused]);

  useEffect(() => {
    scrollToBottom();
  }, [showChat]);

  const searchFriends = async (address) => {
    let friends = await UserService.getAllFriends(account);
    if(friends) friends = friends.filter(friend => !address || friend.walletAddress === address)
    else friends = []
    setFriends(friends)
  }

  const handleOpenOtherPlayer = () => {
    setOpenOthePlayerProfile(true)
  }
  const handleCloseOtherPlayer = () => {
    setOpenOthePlayerProfile(false)
    setFriendAddressSearch("")
  }

  const [onPresentProfileSummaryModal] = useModal(
    <ProfileSummaryModal initialView={ProfileSummaryView.SUMMARY} walletAddress={selectedWalletAddress} />, true, true
  );

  const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    maxWidth: "100%",
  }));

  const scroll = (scrollOffset) => {
    if (scrollElement.current) {
      scrollElement.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <Backdrop>
      <Wrapper>
        {showChat ? (
          <>
            <ChatHeader>
              <div className="listMenuChat">
                <div className="Scrollmenu" ref={scrollElement}>
                  {teams?.map((team: Team, index) => (
                    <div
                      key={index}
                      className={`grid-item ${activedTeam && activedTeam.id === team.id ? "active" : ""}`}
                      onClick={() => handleSelectTeamTab(team)}
                      data-value={index}
                    >
                      {team.teamName}
                    </div>
                  ))}
                  {chatFriends?.filter((friend: Friend) => friend.isShow).map((friend: Friend, index) => (
                    <div
                      key={index}
                      className={`grid-item ${activedChatFriend && activedChatFriend.walletAddress === friend.walletAddress ? "active" : ""}`}
                      onClick={() => handleSelectFriendTab(friend)}
                      data-value={index}
                    >
                      {friend.playerName}
                    </div>
                  ))}
                </div>
              </div>
              <IconButton
                aria-label="close dialog"
                className="close"
                onClick={() => dispatch(setShowChat(false))}
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="back"
                className="back"
                onClick={() => scroll(-80)}
                size="small"
              >
                <ArrowBackIosNewIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="next"
                className="next"
                onClick={() => scroll(80)}
                size="small"
              >
                <ArrowForwardIosIcon fontSize="inherit" />
              </IconButton>
            </ChatHeader>
            <ChatBox>
              {activedTeam && activedTeam.messages?.map((message, index) => (
                  <MessageUI
                    message={message}
                    key={index}
                    onOpenProfileSummaryModal={() => {
                      setSelectedWalletAddress(message.walletAddress)
                      onPresentProfileSummaryModal()
                    }}
                  />
               ))}
               {activedChatFriend && activedChatFriend.messages?.map((message, index) => (
                  <MessageUI
                    message={message}
                    key={index}
                    onOpenProfileSummaryModal={() => {
                      setSelectedWalletAddress(message.walletAddress)
                      onPresentProfileSummaryModal()
                    }}
                  />
               ))}
              {/* other player profile */}
              <Modal
                open={openOthePlayerProfile}
                onClose={handleCloseOtherPlayer}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={PopOverOtherPlayer}>
                  <Box mt={3} ml={2}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <TextField
                        fullWidth
                        label="Wallet Address"
                        color="secondary"
                        sx={{ input: { color: "white", height: 12 } }}
                        focused
                        value={friendAddressSearch}
                        onChange={handleChangeFriendAddressSearch as any}
                      />
                      <Button
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                        color="secondary"
                        onClick={() => searchFriends(friendAddressSearch)}
                      >
                        Search
                      </Button>
                    </Flex>
                  </Box>
                  <Text ml={3} mt={4} color="textSubtle">
                    List Friends:
                  </Text>
                  {friends && friends.length > 0 && <Box
                    sx={{
                      flexGrow: 1,
                      overflowY: "scroll",
                      width: "100%",
                      height: 300,
                    }}
                  >
                    <StyledPaper
                      sx={{
                        my: 1,
                        mx: "auto",
                        p: 2,
                      }}
                    >
                      {friends.map((friend: any) =>
                       <Fragment key={friend.walletAddress}>
                        <Grid container wrap="nowrap" spacing={2}>
                        <Grid item xs zeroMinWidth>
                          <Typography noWrap>{friend.playerName}</Typography>
                          <CopyAddressOtherPlayer account={friend.walletAddress} />
                        </Grid>
                        <Grid item>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <IconButton
                              color="primary"
                              aria-label="chat"
                              size="large"
                              onClick={() => {
                                handleCloseOtherPlayer()
                                dispatch(setChatFriendRequest({senderAddress: account ?? "", receiverAddress: friend.walletAddress, senderName: user.playerName, receiverName: friend.playerName}))
                              }}
                            >
                              <ChatIcon />
                            </IconButton>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                handleCloseOtherPlayer()
                                setSelectedWalletAddress(friend.walletAddress)
                                onPresentProfileSummaryModal()
                              }}
                              aria-label="profile"
                              size="large"
                            >
                              <PersonIcon />
                            </IconButton>
                          </Stack>
                        </Grid>
                      </Grid>
                      </Fragment>
                      )}
                    </StyledPaper>
                  </Box>}
                </Box>
              </Modal>

              <div ref={messagesEndRef} />
              {showEmojiPicker && (
                <EmojiPickerWrapper>
                  <Picker
                    theme="dark"
                    showSkinTones={false}
                    showPreview={false}
                    onSelect={(emoji) => {
                      setMessage(message + emoji.native);
                      setShowEmojiPicker(!showEmojiPicker);
                      dispatch(setFocused(true));
                    }}
                    exclude={["recent", "flags"]}
                  />
                </EmojiPickerWrapper>
              )}
            </ChatBox>
            <InputWrapper onSubmit={handleSubmit}>
              <InputTextField
                inputRef={inputRef}
                autoFocus={focused}
                fullWidth
                placeholder="Press Enter to chat"
                value={message}
                onKeyDown={handleKeyDown}
                onChange={handleChangeMessage as any}
                onFocus={() => {
                  if (!focused) dispatch(setFocused(true));
                }}
                onBlur={() => dispatch(setFocused(false))}
              />
              <IconButton
                aria-label="add menu chat"
                onClick={handleOpenOtherPlayer}
              >
                <AddCircleOutlineIcon />
              </IconButton>
              <IconButton
                aria-label="emoji"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              >
                <InsertEmoticonIcon />
              </IconButton>
            </InputWrapper>
          </>
        ) : (
          <FabWrapper>
            <Fab
              color="secondary"
              aria-label="showChat"
              onClick={() => {
                dispatch(setShowChat(true));
                dispatch(setFocused(true));
              }}
            >
              <ChatBubbleOutlineIcon />
            </Fab>
          </FabWrapper>
        )}
      </Wrapper>
    </Backdrop>
  );
}



export enum ItemType {
  CHAIR,
  COMPUTER,
  WHITEBOARD,
  VENDINGMACHINE,
  CHLAMYDIA,
  ENGINEER,
  HYDROPONICSCIENTIST,
  LABSCIENTIST,
  LOOPY,
  MADMARV,
  MERCHANT,
  MICROBIOLOGIST,
  SPECIMENDEALER,
  STAN,
  TOKENTRADER,
  YUKIHIRA,
}

import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Modal,Text } from '@pancakeswap/uikit'
import { ModalActions, ModalInput } from '../../../dex-components/Modal'
import { useTranslation } from '../../../contexts/Localization'
import { getBalanceAmount, getFullDisplayBalance } from '../../../dex-utils/formatBalance'
import useToast from '../../../hooks/useToast'
import { BIG_ZERO } from '../../../dex-utils/bigNumber'

interface WithdrawModalProps {
  max: BigNumber
  onConfirm: (amount: string) => void
  onDismiss?: () => void 
  tokenName?: string
  LPFee: number
  earnings: BigNumber
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({ onConfirm, onDismiss, max, tokenName = '',LPFee,earnings } ) => {
  const [val, setVal] = useState('')
  const { toastSuccess, toastError } = useToast() as any
  const [pendingTx, setPendingTx] = useState(false)
  const { t } = useTranslation()
  const earningsBigNumber = new BigNumber(earnings)
  let earningsNew = BIG_ZERO
  let newDismiss:any=onDismiss
  
  // If user didn't connect wallet default balance will be 0
  if (!earningsBigNumber.isZero()) {
    earningsNew = getBalanceAmount(earningsBigNumber)
   
  }
  
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max)
  }, [max])

  const valNumber = new BigNumber(val)
  const fullBalanceNumber = new BigNumber(fullBalance)
const lockPercentage= 95
  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setVal(e.currentTarget.value.replace(/,/g, '.'))
      }
    },
    [setVal],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

  return (
    <Modal title={t('Unstake LP tokens')} onDismiss={onDismiss}>
        <Text bold textTransform="uppercase" color="textSubtle" fontSize="16px">
          This withdrawal will result in a {LPFee*100}% fee. <u><a href="https://app.gitbook.com/s/VzpCckVhsjZanj4z1yWi/supaverse/supa-hq/the-lab/microbiologist-lp-staking/specimen-staking-and-withdrawal-fees" target="_blank" rel="noreferrer">CLICK HERE FOR MORE INFO</a></u>
          </Text>
          <Text bold textTransform="uppercase" color="textSubtle" fontSize="16px">
            All your SUPA will also be harvested where a proportion of it will be vested. See the harvest section for more info.
          </Text>
      <ModalInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={tokenName}
        inputTitle={t('Unstake')}
      />
      <ModalActions>
        <Button variant="secondary" onClick={onDismiss } width="100%" disabled={pendingTx}>
          {t('Cancel')}
        </Button>
        <Button
          disabled={pendingTx || !valNumber.isFinite() || valNumber.eq(0) || valNumber.gt(fullBalanceNumber)}
          onClick={async () => {
            setPendingTx(true)
            try {
              await onConfirm(val)
              toastSuccess(t('Unstaked!'), t('Your earnings have also been harvested to your wallet'))
              newDismiss()
            } catch (e) {
              toastError(
                t('Error'),
                t('Please try again. Confirm the transaction and make sure you are paying enough gas!'),
              )
              console.error(e)
            } finally {
              setPendingTx(false)
            }
          }}
          width="100%"
        >
          {pendingTx ? t('Confirming') : t('Confirm')}
        </Button>
      </ModalActions>
      <Text bold textTransform="uppercase" color="textSubtle" fontSize="16px">
            There will be a {!Number.isNaN(parseFloat(val))?parseFloat(val)*LPFee:0} fee in Specimen-LP tokens
          </Text>
          <Text bold textTransform="uppercase" color="textSubtle" fontSize="16px">
           On withdrawal of your Specimen-LP, you will receive {earningsNew.multipliedBy(100-lockPercentage).div(100).toFixed(3, BigNumber.ROUND_DOWN)} SUPA, While {earningsNew.multipliedBy(lockPercentage).div(100).toFixed(3, BigNumber.ROUND_DOWN)} SUPA will be vested.

          </Text>
    </Modal>
  )
}

export default WithdrawModal

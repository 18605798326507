import React, {useEffect, useState} from "react";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "../../../contexts/Localization";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ChatIcon from "@mui/icons-material/Chat";
import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import Popover from "@mui/material/Popover";
import {Flex, Text, useModal} from "@pancakeswap/uikit";
import Button from "@mui/material/Button";
import CopyAddressOtherPlayer from "./CopyAddressOtherPlayer";
import UserService from "../../../services/UserService";
import TeamService from "../../../services/TeamService";
import ToastContainer from "../../Toast/ToastContainer";
import ProfileSummaryModal, {ProfileSummaryView} from "./ProfileSummaryModal";
import { setChatFriendRequest } from "../../../state/ChatStore";
import { useAppDispatch } from "../../../hooks";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));

const ProfileButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#42eacb',
  color: 'rgba(0, 0, 0, 0.87)',
  '&:hover': {
    backgroundColor: 'rgb(46, 163, 142)',
  },
  '&:disabled': {
    backgroundColor: 'rgba(46, 163, 142, .5)',
    color: 'rgba(0, 0, 0, 0.5)',
  },
}));

interface ProfileFriendsProps {
  onDismiss: any;
  onChangeTab: any;
  walletAddress?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ViewProfileFriend {
  profile: any;
  isFriend: boolean
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProfileFriends: React.FC<ProfileFriendsProps> = ({ onDismiss, onChangeTab, walletAddress }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const [user, setUser] = useState<any>()
  const [value, setValue] = React.useState(0);
  const [friends, setFriends] = useState([])
  const [pendingRequests, setPendingRequests] = useState([])
  const [removeWallet, setRemoveWallet] = useState<string>('');
  const [deletePopOver, setDeletePopOver] = React.useState<HTMLButtonElement | null>(null);
  const [viewFriendWallet, setViewFriendWallet] = useState<string>('');
  const [isOpenOtherProfile, setIsOpenOtherProfile] = useState<boolean>(false)
  const [viewFriendProfile, setViewFriendProfile] = useState<ViewProfileFriend>({profile: null, isFriend: false});
  const [anchorElPopOver, setanchorElPopOver] = React.useState<HTMLButtonElement | null>(null);
  const [isRequested, setIsRequested] = useState<boolean>(false)
  const [isRemoved, setIsRemoved] = useState<boolean>(false)
  const [currentTeam, setCurrentTeam] = useState<any>(null)
  const [toasts, setToasts] = useState([]);

  const currentUserWallet = account as any

  const loadUser = async () => {
    const user = await UserService.getUserByAddress(account)
    setUser(user)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const setProfileToShow = (address: string) => {
    const addedFriends = friends.filter((friend: any) => friend.walletAddress == address)
    if (addedFriends && addedFriends.length) {
      setIsRequested(true)
      setViewFriendProfile({profile: addedFriends[0], isFriend: true})
    }

    const pendingFriends = pendingRequests.filter((friend: any) => friend.walletAddress == address)
    if (pendingFriends && pendingFriends.length) {
      setIsRequested(true)
      setViewFriendProfile({profile: pendingFriends[0], isFriend: false})
    }
  }

  const loadAllFriends = async (address: string) => {
    const allFriends = await UserService.getAllFriends(address)
    setFriends(allFriends ?? [])
  }

  const loadAllPendingRequests = async (address: string) => {
    const requests = await UserService.getAllPendingFriends(address)
    setPendingRequests(requests ?? [])
  }

  const addFriend = async (address: string) => {
    setIsRequested(true)
    return await UserService.addFriend(account, {address})
  }

  const approveFriend = async (address: string) => {
    await UserService.approveFriend(address, {address: currentUserWallet})
    loadAllFriends(currentUserWallet)
    loadAllPendingRequests(currentUserWallet)
  }

  const removeFriend = async (address: string) => {
    setIsRemoved(true)
    await UserService.removeFriend(address, {address: currentUserWallet})
    loadAllFriends(currentUserWallet)
    loadAllPendingRequests(currentUserWallet)
  }

  const handleClickAcceptRequest = (address) => {
    approveFriend(address)
  }

  useEffect(() => {
    if(walletAddress) {
      loadAllPendingRequests(walletAddress)
      loadAllFriends(walletAddress)
      loadTeam(account ?? "")
    }
  }, [walletAddress])

  useEffect(() => {
    if(account) {
      loadTeam(account)
      loadUser()
    }
  }, [account])

  const openDeletePopOver = Boolean(deletePopOver);
  const idDeletePopOver = openDeletePopOver ? "simple-popover" : undefined;
  const handleClickDeletePopOver = (
    event: React.MouseEvent<HTMLButtonElement>, walletAddress: string
  ) => {
    setDeletePopOver(event.currentTarget);
    setRemoveWallet(walletAddress)
  };

  const handleCloseDeletePopOver = () => {
    setDeletePopOver(null);
    setRemoveWallet('');
  };

  const handleConfirmDeletePopOver = () => {
    removeFriend(removeWallet)
    setDeletePopOver(null)
  }

  const openPopOver = Boolean(anchorElPopOver);
  const idPopOver = openPopOver ? "simple-popover" : undefined;
  const handleClickPopOver = (event: React.MouseEvent<HTMLButtonElement>, walletAddress: string) => {
    setanchorElPopOver(event.currentTarget);
    setViewFriendWallet(walletAddress)
    setIsOpenOtherProfile(true)
  };

  const handleClosePopOver = () => {
    setanchorElPopOver(null);
    setViewFriendProfile({profile: null, isFriend: false})
    setIsOpenOtherProfile(false)
    setViewFriendWallet('')
  };

  useEffect(() => {
    if (viewFriendWallet) {
      setProfileToShow(viewFriendWallet)
    }
  }, [viewFriendWallet])

  useEffect(() => {
    if (isOpenOtherProfile) {
      onChangeTab(0)
      onPresentProfileSummaryModal()
    }
  }, [isOpenOtherProfile])


  const [onPresentProfileSummaryModal] = useModal(
    <ProfileSummaryModal initialView={ProfileSummaryView.SUMMARY} walletAddress={viewFriendWallet} />, true, true, "modal_in_friends"
  );

  const loadTeam = async (address: string) => {
    const user = await UserService.getUserByAddress(address)
    if(user && user.team){
      const team = await TeamService.getTeamById(user.team)
      setCurrentTeam(team)
    }else setCurrentTeam(null)
  }

  const sendTeamRequest = async (teamId: string, userAddress: string, playerName: string) => {
     await TeamService.inviteUser({userAddress, teamId})
     showNotification("Team request sent", `Please wait for "${playerName}" to accept`)
     if(account) loadTeam(account)
  }

  const showNotification = (title: string, description: string) => {
    const now = Date.now();
    const randomToast = {
      id: `id-${now}`,
      title,
      description,
      type: ""
    } as never;
    setToasts((prevToasts: never[]) => [randomToast, ...prevToasts]);
  };

  const handleRemoveNotification = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((prevToast: any) => prevToast.id !== id));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            label={<span style={{ color: "#B8ADD2" }}>Friends</span>}
            {...a11yProps(0)}
          />
          <Tab
            label={
              <span style={{ color: "#B8ADD2" }}>
                Pending {pendingRequests && pendingRequests.length ? <CircleIcon fontSize="inherit" /> : ''}
              </span>
            }
            {...a11yProps(0)}
          />
        </Tabs>
      </Box>
      {/* Friends & Pending - popover */}
      <Popover
        id={idPopOver}
        open={openPopOver}
        anchorEl={anchorElPopOver}
        onClose={handleClosePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
            width: 400,
            height: 220,
            padding: 2,
            backgroundColor: "#222639",
            color: "#eee",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <img
                src={`https://via.placeholder.com/120?w=164&h=164&fit=crop&auto=format`}
                srcSet={`https://via.placeholder.com/120?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={"profile"}
                loading="lazy"
              />
            </Grid>
            <Grid item xs={8}>
              <Flex alignItems="center" justifyContent="space-between">
                <Text color="textSubtle">Name:</Text>
                <Text>{ viewFriendProfile.profile?.playerName }</Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Text color="textSubtle">Wallet:</Text>
                <CopyAddressOtherPlayer account={viewFriendProfile.profile?.walletAddress ?? ''} />
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Text color="textSubtle">Level:</Text>
                <Text>{ viewFriendProfile.profile?.level ?? 1 }</Text>
                <Text color="textSubtle">/XP:</Text>
                <Text>{ viewFriendProfile.profile?.xp ?? 0 }</Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Text color="textSubtle">Team:</Text>
                <Text>Supa Team</Text>
              </Flex>
            </Grid>
            <Grid item xs>
              {
                !viewFriendProfile.isFriend && <ProfileButton
                  sx={{ marginTop: 2 }}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={isRequested}
                  onClick={() => addFriend(viewFriendProfile.profile?.walletAddress)}
                >
                  Send Friend Request
                </ProfileButton>
              }
              {currentTeam && viewFriendProfile && viewFriendProfile.profile && <ProfileButton
                sx={{ marginTop: 2 }}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={currentTeam.teamMembers.some((member) => member.walletAddress === viewFriendProfile.profile.walletAddress)}
                onClick={() => sendTeamRequest(currentTeam._id, viewFriendProfile.profile.walletAddress ?? "", viewFriendProfile.profile.playerName)}
              >
                Send Team Request
              </ProfileButton>}
              <ProfileButton
                sx={{ marginTop: 2 }}
                variant="contained"
                color="secondary"
                fullWidth
              >
                View Inventory
              </ProfileButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>

      {/* Pop Over Delete */}
      <Popover
        id={idDeletePopOver}
        open={openDeletePopOver}
        anchorEl={deletePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            padding: 2,
            backgroundColor: "#222639",
            color: "#eee",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography align={"center"}>
                  Are you sure you want to remove this friend?
              </Typography>
            </Grid>
            <Grid item xs>
              <Flex alignItems="center" justifyContent="space-between">
                <Button
                  sx={{ marginTop: 2, marginRight: 2 }}
                  variant="outlined"
                  color="secondary"
                  onClick={handleCloseDeletePopOver}
                  fullWidth
                >
                  No
                </Button>
                <Button
                  sx={{ marginTop: 2 }}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleConfirmDeletePopOver}
                >
                  Yes
                </Button>
              </Flex>
            </Grid>
          </Grid>
        </Box>
      </Popover>

      {/* Friends - card */}
      <TabPanel value={value} index={0}>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
            width: "100%",
            height: 300,
          }}
        >
          {
            friends && friends.map(
              (friend: any, index: number) => <StyledPaper
                key={index}
                sx={{
                  my: 1,
                  mx: "auto",
                  p: 2,
                }}
              >
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs zeroMinWidth>
                    <Typography noWrap>{ friend.playerName }</Typography>
                    <CopyAddressOtherPlayer account={friend.walletAddress ?? ''} />
                  </Grid>
                  <Grid item>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <IconButton 
                         color="primary" 
                         aria-label="chat" 
                         size="large"
                         onClick={() => {
                           dispatch(setChatFriendRequest({senderAddress: account ?? "", receiverAddress: friend.walletAddress, senderName: user.playerName, receiverName: friend.playerName}))
                           handleClosePopOver()
                          }}
                      >
                        <ChatIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={(event) => handleClickPopOver(event, friend.walletAddress)}
                        aria-label="profile"
                        size="large"
                      >
                        <PersonIcon />
                      </IconButton>
                      {
                        account == walletAddress && <IconButton
                            color="primary"
                            onClick={(event) => handleClickDeletePopOver(event, friend.walletAddress)}
                            aria-label="delete"
                            size="large"
                        >
                            <ClearIcon />
                        </IconButton>
                      }
                    </Stack>
                  </Grid>
                </Grid>
              </StyledPaper>
            )
          }
        </Box>
      </TabPanel>

      {/* Pending - card */}
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
            width: "100%",
            height: 300,
          }}
        >
          {
            pendingRequests.map(
              (friendRequest: any, index: number) =>
                <StyledPaper
                  key={index}
                  sx={{
                    my: 1,
                    mx: "auto",
                    p: 2,
                  }}
                >
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs zeroMinWidth>
                      <Typography noWrap>{ friendRequest.playerName }</Typography>
                      <CopyAddressOtherPlayer account={friendRequest.walletAddress ?? ''} />
                    </Grid>
                    <Grid item>
                      <Stack direction="row" alignItems="center" spacing={1}>
                      {
                          account == walletAddress && (
                            <>
                              <IconButton
                                color="primary"
                                aria-label="check"
                                size="large"
                                onClick={() => handleClickAcceptRequest(friendRequest.walletAddress)}
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                color="primary"
                                onClick={(event) => handleClickDeletePopOver(event, friendRequest.walletAddress)}
                                aria-label="reject"
                                size="large"
                              >
                                <ClearIcon />
                              </IconButton>
                            </>
                          )
                        }
                        <IconButton
                          color="primary"
                          aria-label="profile"
                          size="large"
                          onClick={(event) => handleClickPopOver(event, friendRequest.walletAddress)}
                        >
                          <PersonIcon />
                        </IconButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </StyledPaper>
            )
          }
        </Box>
      </TabPanel>
      <ToastContainer toasts={toasts} onRemove={handleRemoveNotification} />
    </Box>
  );
};

export default ProfileFriends;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import SuspenseWithChunkError from "./dex-components/SuspenseWithChunkError";
import PageLoader from "./dex-components/Loader/PageLoader";
import history from "./routerHistory";
import GlobalStyle from "./style/Global";
import { ResetCSS } from "@pancakeswap/uikit";

import { useAppSelector } from "./hooks";
import useEagerConnect from "./hooks/useEagerConnect";
import useScrollOnRouteChange from "./hooks/useScrollOnRouteChange";
import useUserAgent from "./hooks/useUserAgent";
import { useInactiveListener } from "./hooks/useInactiveListener";
import { usePollBlockNumber } from "./state/block/hooks";
import { usePollCoreFarmData } from "./state/farms/hooks";
import RoomSelectionDialog from "./components/RoomSelectionDialog";
import LoginDialog from "./components/LoginDialog";
import ComputerDialog from "./components/ComputerDialog";
import WhiteboardDialog from "./components/WhiteboardDialog";
import VideoConnectionDialog from "./components/VideoConnectionDialog";
import Chat from "./components/Chat";
import HelperButtonGroup from "./components/HelperButtonGroup";
import MadMarvDialog from "./components/npc/MadMarvDialog";
import ChlamydiaDialog from "./components/npc/ChlamydiaDialog";
import StanDialog from "./components/npc/StanDialog";
import YukihiraDialog from "./components/npc/YukihiraDialog";
import LoopyDialog from "./components/npc/LoopyDialog";
import MerchantDialog from "./components/npc/MerchantDialog";
import EngineerDialog from "./components/npc/EngineerDialog";
import LabScientistDialog from "./components/npc/LabScientistDialog";
import HydroponicScientistDialog from "./components/npc/HydroponicScientistDialog";
import MicroBiologistDialog from "./components/npc/MicroBiologistDialog";
import SpecimenDealerDialog from "./components/npc/SpecimenDealerDialog";
import TokenTraderDialog from "./components/npc/TokenTraderDialog";
import Menu from "./dex-components/Menu/";
import BigNumber from "bignumber.js";
import UserMenu from "./dex-components/Menu/UserMenu";
import { ToastListener } from "./contexts/ToastsContext";
import { checkAutoLogin, getTokenInLocalStorage, removeTokenInLocalStorage } from "./services/AuthService";

const WrapMenu = styled.div`
  position: fixed;
  top: 10px;
  display: flex;
  flex-direction: column;
  color: black !important;
  background: white;
`;

const Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});
function App() {
  const { account } = useWeb3React();

  usePollBlockNumber();
  useEagerConnect();
  usePollCoreFarmData();
  useScrollOnRouteChange();
  useUserAgent();
  useInactiveListener();
  const loggedIn = useAppSelector((state) => state.userGame.loggedIn);
  const computerDialogOpen = useAppSelector((state) => state.computer.computerDialogOpen);
  const whiteboardDialogOpen = useAppSelector((state) => state.whiteboard.whiteboardDialogOpen);
  const videoConnected = useAppSelector((state) => state.userGame.videoConnected);
  const roomJoined = useAppSelector((state) => state.room.roomJoined);
  const microBiologistDialogOpen = useAppSelector((state) => state.microBiologist.microBiologistDialogOpen);
  const specimenDealerDialogOpen = useAppSelector((state) => state.specimenDealer.specimenDealerDialogOpen);
  const tokenTraderDialogOpen = useAppSelector((state) => state.tokenTrader.tokenTraderDialogOpen);
  const hydroponicScientistDialogOpen = useAppSelector((state) => state.hydroponicScientist.hydroponicScientistDialogOpen);
  const labScientistDialogOpen = useAppSelector((state) => state.labScientist.labScientistDialogOpen);
  const engineerDialogOpen = useAppSelector((state) => state.engineer.engineerDialogOpen);
  const madMarvDialogOpen = useAppSelector((state) => state.madMarv.madMarvDialogOpen);
  const chlamydiaDialogOpen = useAppSelector((state) => state.chlamydia.chlamydiaDialogOpen);
  const stanDialogOpen = useAppSelector((state) => state.stan.stanDialogOpen);
  const yukihiraDialogOpen = useAppSelector((state) => state.yukihara.yukihiraDialogOpen);
  const loopyDialogOpen = useAppSelector((state) => state.loopy.loopyDialogOpen);
  const merchantDialogOpen = useAppSelector((state) => state.merchant.merchantDialogOpen);

  let ui: JSX.Element;
  if (loggedIn) {
    if (computerDialogOpen) {
      /* Render ComputerDialog if user is using a computer. */
      ui = <ComputerDialog />;
    } else if (whiteboardDialogOpen) {
      /* Render WhiteboardDialog if user is using a whiteboard. */
      ui = <WhiteboardDialog />;
    } else if (microBiologistDialogOpen) {
      ui = <MicroBiologistDialog />;
    } else if (specimenDealerDialogOpen) {
      ui = <SpecimenDealerDialog />;
    } else if (tokenTraderDialogOpen) {
      ui = <TokenTraderDialog />;
    } else if (hydroponicScientistDialogOpen) {
      ui = <HydroponicScientistDialog />;
    } else if (labScientistDialogOpen) {
      ui = <LabScientistDialog />;
    } else if (engineerDialogOpen) {
      ui = <EngineerDialog />;
    } else if (madMarvDialogOpen) {
      ui = <MadMarvDialog />;
    } else if (chlamydiaDialogOpen) {
      ui = <ChlamydiaDialog />;
    } else if (stanDialogOpen) {
      ui = <StanDialog />;
    } else if (yukihiraDialogOpen) {
      ui = <YukihiraDialog />;
    } else if (loopyDialogOpen) {
      ui = <LoopyDialog />;
    } else if (merchantDialogOpen) {
      ui = <MerchantDialog />;
    } else {
      ui = (
        /* Render Chat or VideoConnectionDialog if no dialogs are opened. */
        <>
          <Chat />{" "}
          <WrapMenu>
            {" "}
            <Menu />
          </WrapMenu>
          {!videoConnected && <VideoConnectionDialog />}
          {/* Render VideoConnectionDialog if user is not connected to a webcam. */}
          {}
        </>
      );
    }
  } else if (roomJoined) {
    /* Render LoginDialog if not logged in but selected a room. */
    ui = <LoginDialog />;
  } else {
    /* Render RoomSelectionDialog if yet selected a room. */
    ui = <RoomSelectionDialog />;
  }

  return (
    <Router history={history}>
      <Backdrop>
        {ui}
        {/* Render HelperButtonGroup if no dialogs are opened. */}
        {!computerDialogOpen && !whiteboardDialogOpen && <HelperButtonGroup />}
      </Backdrop>
      <ToastListener />
    </Router>
  );
}

export default App;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface HydroponicScientistState {
  hydroponicScientistDialogOpen: boolean;
  hydroponicScientistId: null | string;
  hydroponicScientistUrl: null | string;
  urls: Map<string, string>;
}

const initialState: HydroponicScientistState = {
  hydroponicScientistDialogOpen: false,
  hydroponicScientistId: null,
  hydroponicScientistUrl: null,
  urls: new Map(),
};

export const hydroponicScientistSlice = createSlice({
  name: "hydroponicScientist",
  initialState,
  reducers: {
    openHydroponicScientistDialog: (state, action: PayloadAction<string>) => {
      state.hydroponicScientistDialogOpen = true;
      state.hydroponicScientistId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.hydroponicScientistUrl = url;
    },
    closeHydroponicScientistDialog: (state) => {
      state.hydroponicScientistDialogOpen = false;
      state.hydroponicScientistId = null;
      state.hydroponicScientistUrl = null;
    },
  },
});

export const { openHydroponicScientistDialog, closeHydroponicScientistDialog } = hydroponicScientistSlice.actions;

export default hydroponicScientistSlice.reducer;

import React, {useEffect, useState} from "react";
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
} from "@pancakeswap/uikit";
import { useTranslation } from "../../../contexts/Localization";
import styled from "styled-components";
import ProfileInfo from "./ProfileInfo";
import ProfileInventory from "./ProfileInventory";
import ProfileFriends from "./ProfileFriends";
import ProfileTeam from "./ProfileTeam";

export enum ProfileSummaryView {
  SUMMARY,
  INVENTORY,
  ITEM,
  FRIENDS,
  TEAM,
}

interface ProfileSummaryModalProps extends InjectedModalProps {
  initialView?: ProfileSummaryView;
  walletAddress?: string
}

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
`;

const Tabs = styled.div`
  background-color: ${({ theme }) => theme.colors.dropdown};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding: 16px 24px;
`;

const ProfileSummaryModal: React.FC<ProfileSummaryModalProps> = ({
  initialView = ProfileSummaryView.SUMMARY,
  walletAddress,
  onDismiss,
}) => {
  const [view, setView] = useState(initialView);
  const { t } = useTranslation();

  const handleClick = (newIndex: number) => {
    setView(newIndex);
  };

  return (
    <ModalContainer title={t("Profile!")} minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t("Profile Summary")}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <Tabs>
        <ButtonMenu
          scale="sm"
          variant="subtle"
          onItemClick={handleClick}
          activeIndex={view}
          fullWidth
        >
          <ButtonMenuItem>{t("Summary")}</ButtonMenuItem>
          <ButtonMenuItem>{t("Inventory")}</ButtonMenuItem>
          <ButtonMenuItem>{t("Item")}</ButtonMenuItem>
          <ButtonMenuItem>{t("Friends")}</ButtonMenuItem>
          <ButtonMenuItem>{t("Team")}</ButtonMenuItem>
        </ButtonMenu>
      </Tabs>
      <ModalBody p="24px" width="100%">
        {view === ProfileSummaryView.SUMMARY && (
          <ProfileInfo onDismiss={onDismiss} walletAddress={walletAddress}/>
        )}
        {view === ProfileSummaryView.INVENTORY && (
          <ProfileInventory onDismiss={onDismiss} walletAddress={walletAddress}/>
        )}
        {view === ProfileSummaryView.FRIENDS && (
          <ProfileFriends onDismiss={onDismiss} onChangeTab={setView} walletAddress={walletAddress}/>
        )}
        {view === ProfileSummaryView.TEAM && (
          <ProfileTeam onDismiss={onDismiss} walletAddress={walletAddress}/>
        )}
      </ModalBody>
    </ModalContainer>
  );
};

export default ProfileSummaryModal;

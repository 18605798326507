import Phaser from "phaser";

export const phaserEvents = new Phaser.Events.EventEmitter();

export enum Event {
  PLAYER_JOINED_GAME = "player-joined",
  PLAYER_JOINED_SCENE1 = "player-joined-scene1",
  PLAYER_JOINED_SCENE2 = "player-joined-scene2",
  PLAYER_JOINED_SCENE3 = "player-joined-scene3",
  PLAYER_JOINED_SCENE4 = "player-joined-scene4",
  PLAYER_JOINED_SCENE5 = "player-joined-scene5",

  PLAYER_UPDATED_SCENE1 = "player-updated-scene1",
  PLAYER_UPDATED_SCENE2 = "player-updated-scene2",
  PLAYER_UPDATED_SCENE3 = "player-updated-scene3",
  PLAYER_UPDATED_SCENE4 = "player-updated-scene4",
  PLAYER_UPDATED_SCENE5 = "player-updated-scene5",

  PLAYER_LEFT = "player-left",
  PLAYER_DISCONNECTED = "player-disconnected",
  MY_PLAYER_READY = "my-player-ready",
  MY_PLAYER_NAME_CHANGE = "my-player-name-change",
  MY_PLAYER_TEXTURE_CHANGE = "my-player-texture-change",
  MY_PLAYER_VIDEO_CONNECTED = "my-player-video-connected",
  ITEM_USER_ADDED = "item-user-added",
  ITEM_USER_REMOVED = "item-user-removed",
  UPDATE_DIALOG_BUBBLE = "update-dialog-bubble",
}

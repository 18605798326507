import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EngineerState {
  engineerDialogOpen: boolean;
  engineerId: null | string;
  engineerUrl: null | string;
  urls: Map<string, string>;
}

const initialState: EngineerState = {
  engineerDialogOpen: false,
  engineerId: null,
  engineerUrl: null,
  urls: new Map(),
};

export const engineerSlice = createSlice({
  name: "engineer",
  initialState,
  reducers: {
    openEngineerDialog: (state, action: PayloadAction<string>) => {
      state.engineerDialogOpen = true;
      state.engineerId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.engineerUrl = url;
    },
    closeEngineerDialog: (state) => {
      state.engineerDialogOpen = false;
      state.engineerId = null;
      state.engineerUrl = null;
    },
  },
});

export const { openEngineerDialog, closeEngineerDialog } = engineerSlice.actions;

export default engineerSlice.reducer;

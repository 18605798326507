import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TokenTraderState {
  tokenTraderDialogOpen: boolean;
  tokenTraderId: null | string;
  tokenTraderUrl: null | string;
  urls: Map<string, string>;
}

const initialState: TokenTraderState = {
  tokenTraderDialogOpen: false,
  tokenTraderId: null,
  tokenTraderUrl: null,
  urls: new Map(),
};

export const tokenTraderSlice = createSlice({
  name: "tokenTrader",
  initialState,
  reducers: {
    openTokenTraderDialog: (state, action: PayloadAction<string>) => {
      state.tokenTraderDialogOpen = true;
      state.tokenTraderId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.tokenTraderUrl = url;
    },
    closeTokenTraderDialog: (state) => {
      state.tokenTraderDialogOpen = false;
      state.tokenTraderId = null;
      state.tokenTraderUrl = null;
    },
  },
});

export const { openTokenTraderDialog, closeTokenTraderDialog } =
  tokenTraderSlice.actions;

export default tokenTraderSlice.reducer;

export const MAPS = {
  firstLevel: {
    file: "Supa_Lounge_v4.json",
    key: "first-level",
  },
  secondLevel: {
    file: "Right_Corridor.json",
    key: "second-level",
  },
  thirdLevel: {
    file: "Lab.json",
    key: "third-level",
  },
  fourthLevel: {
    file: "Left_Corridor.json",
    key: "fourth-level",
  },
  fifthLevel: {
    file: "Engineering_Bay.json",
    key: "fifth-level",
  },
  sixthLevel: {
    file: "Hydroponic_Farm.json",
    key: "sixth-level",
  },
} as const;

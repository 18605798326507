export default {
  masterChef: {
    97: '0xf39c314a6d98e7e6D56583E92ec229B51795058B',
    4002: '0xAD7278B2737567cF1A7d940973E10191efE34803',
  },
  masterScientist: {
    97: '0xf39c314a6d98e7e6D56583E92ec229B51795058B',
    4002: '0xAD7278B2737567cF1A7d940973E10191efE34803',
  },
  multiCall: {
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    4002: '0xeA8504A2b4F1D326A20b85bA861661A4dd4Cf48e',
  },
  nft: {
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    4002: '0x01c0C89660b4cEcee4b221010e7c35850D9fFAAC',
  },
}

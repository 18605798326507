import { enableMapSet } from "immer";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";
import cloneDeep from "lodash/cloneDeep";
import { useDispatch } from "react-redux";
import farmsReducer from "./farms";

import blockReducer from "./block";

import { updateVersion } from "./global/actions";
import user, { initialState as userInitialState } from "./user/reducer";
import transactions, { initialState as transactionsInitialState } from "./transactions/reducer";
import swap from "./swap/reducer";
import mint from "./mint/reducer";
import lists, { initialState as listsInitialState } from "./lists/reducer";
import burn from "./burn/reducer";
import multicall from "./multicall/reducer";

import userReducer from "./UserStore";
import computerReducer from "./ComputerStore";
import whiteboardReducer from "./WhiteboardStore";
import chatReducer from "./ChatStore";
import roomReducer from "./RoomStore";
import chlamydiaReducer from "./npc/ChlamydiaStore";
import engineerReducer from "./npc/EngineerStore";
import hydroponicScientistReducer from "./npc/HydroponicScientistStore";
import labScientistReducer from "./npc/LabScientistStore";
import loopyReducer from "./npc/LoopyStore";
import madMarvReducer from "./npc/MadMarvStore";
import merchantReducer from "./npc/MerchantStore";
import microBiologistReducer from "./npc/MicrobiologistStore";
import specimenDealerReducer from "./npc/SpecimenDealerStore";
import stanReducer from "./npc/StanStore";
import tokenTraderReducer from "./npc/TokenTraderStore";
import yukiharaReducer from "./npc/YukihiraStore";

const PERSISTED_KEYS: string[] = ["user", "transactions", "lists", "profile"];

enableMapSet();

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    userGame: userReducer,
    computer: computerReducer,
    whiteboard: whiteboardReducer,
    chat: chatReducer,
    room: roomReducer,
    chlamydia: chlamydiaReducer,
    engineer: engineerReducer,
    hydroponicScientist: hydroponicScientistReducer,
    labScientist: labScientistReducer,
    loopy: loopyReducer,
    madMarv: madMarvReducer,
    merchant: merchantReducer,
    microBiologist: microBiologistReducer,
    specimenDealer: specimenDealerReducer,
    stan: stanReducer,
    tokenTrader: tokenTraderReducer,
    yukihara: yukiharaReducer,
    block: blockReducer,
    farms: farmsReducer,
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
  },
  // Temporary disable serialize check for redux as we store MediaStream in ComputerStore.
  // https://stackoverflow.com/a/63244831
  middleware: [...getDefaultMiddleware({ thunk: true, serializableCheck: false }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({
    states: PERSISTED_KEYS,
    preloadedState: {
      user: cloneDeep(userInitialState),
      transactions: cloneDeep(transactionsInitialState),
      lists: cloneDeep(listsInitialState),
    },
  }),
});
store.dispatch(updateVersion());

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();

export default store;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoopyState {
  loopyDialogOpen: boolean;
  loopyId: null | string;
  loopyUrl: null | string;
  urls: Map<string, string>;
}

const initialState: LoopyState = {
  loopyDialogOpen: false,
  loopyId: null,
  loopyUrl: null,
  urls: new Map(),
};

export const loopySlice = createSlice({
  name: "loopy",
  initialState,
  reducers: {
    openLoopyDialog: (state, action: PayloadAction<string>) => {
      state.loopyDialogOpen = true;
      state.loopyId = action.payload;
      const url = state.urls.get(action.payload);
      if (url) state.loopyUrl = url;
    },
    closeLoopyDialog: (state) => {
      state.loopyDialogOpen = false;
      state.loopyId = null;
      state.loopyUrl = null;
    },
  },
});

export const { openLoopyDialog, closeLoopyDialog } = loopySlice.actions;

export default loopySlice.reducer;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "../../../contexts/Localization";
import BigNumber from 'bignumber.js'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled, alpha } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Avatar from "@mui/material/Avatar";
import { deepOrange, green } from "@mui/material/colors";
import multicall from "../../../dex-utils/multicall";
import nftApi from '../../../config/abi/nft.json'
import { getAddress } from "../../../dex-utils/addressHelpers";
import addresses from '../../../config/constants/contracts'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 400,
  color: theme.palette.text.primary,
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Equippeds = ["All", "Tokens", "Head", "Body", "Legs", "Feet", "Holotube", "Synergy Tube"]

const Affinities = ["WH", "GR", "BU", "BK", "PR", "RD", "OR"]

interface ProfileInventoryProps {
  onDismiss: any;
  walletAddress?: string;
}

const ProfileInventory: React.FC<ProfileInventoryProps> = ({ onDismiss }) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const [items, setItems] = useState<any>([])

  const nftContractAddress = getAddress(addresses.nft)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleSelect = (value: string) => {
    setAnchorEl(null);
  }

  const getItemsFromJsonFiles = async (files: string[]) => {
    let promises = [] as any
    files.forEach((file: string) => {
       if(file) promises.push(axios.get(file));
    })

    Promise.all(promises).then(function(results) {
      const items = results.map((result) => {
          let affinity = result.affinity.reduce((previous: string, current: number, index: number) => {
             if(current !== 0){
               if(previous === "") return `${current} ${Affinities[index]}`
               else return `${previous}, ${current} ${Affinities[index]}`
             }
             else return previous
          })
          return {name: result.name, image: result.image, affinity}
      }, "")
      setItems(items)
    });
  }

 const getTokenURIs = async (tokens: number[]) => {
    const calls = [] as any
    tokens.forEach((token: number) => {
      calls.push({address: nftContractAddress, name: 'tokenURI', params: [token]})
    })
    const tokenURIs = await multicall(nftApi, calls)
    return tokenURIs
 } 

 const getTokens = async (numberOfToken: number) => {
    const calls = [] as any
    for(var index=0; index<numberOfToken; index++){
      calls.push({address: nftContractAddress, name: 'tokenOfOwnerByIndex', params: [account, index]})
    }
    const tokens = await multicall(nftApi, calls)
    const parsedTokens = tokens.map((token) => {
      return new BigNumber(token).toNumber()
    })
    return parsedTokens
 }
  
 const getTokenBalance = async () => {
    const calls = [{address: nftContractAddress, name: 'balanceOf', params: [account]}]
    const rawTokenBalances = await multicall(nftApi, calls)
    const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
      return new BigNumber(tokenBalance).toNumber()
    })
    return parsedTokenBalances[0]
  }

  const loadData = async (type: string) => {
     const tokenBalance = await getTokenBalance()
     if(tokenBalance > 0) {
       const tokens = await getTokens(tokenBalance)
       const files = await getTokenURIs(tokens)
       getItemsFromJsonFiles(files)
    }
  }

  useEffect(() => {
      loadData("All")
  }, [])

  return (
    <>
      <Box>
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          color="secondary"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Equipped
        </Button>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <div>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {Equippeds.map((equipped, index) => (<MenuItem key={index} onClick={() => handleSelect(equipped)} disableRipple>
                  {equipped}
                </MenuItem>))}
              </StyledMenu>
            </div>
            <Box
              sx={{
                flexGrow: 1,
                overflow: "hidden",
                width: "100%",
                height: 300,
                my: 1,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: deepOrange[500],
                  width: "100%",
                  height: 150,
                  my: 1,
                }}
                variant="square"
              >
                 N
              </Avatar>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                flexGrow: 1,
                overflowY: "scroll",
                width: "100%",
                height: 350,
              }}
            >
              {items.map((item, index) => (<StyledPaper
                key={index}
                sx={{
                  my: 2,
                  mx: "auto",
                  p: 2,
                }}
              >
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs zeroMinWidth>
                    <Avatar
                      sx={{
                        bgcolor: deepOrange[500],
                        width: "100%",
                        height: 80,
                      }}
                      variant="square"
                    >
                      <img src={item.image}/>
                    </Avatar>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography noWrap>{item.name}</Typography>
                    <Typography noWrap>{item.affinity}</Typography>
                    <Typography noWrap></Typography>
                  </Grid>
                </Grid>
              </StyledPaper>))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProfileInventory;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChatMessage } from "../../../types/IOfficeState";
import { SCENES } from "../constants/scenes";
import phaserGame from "../PhaserGame";
import FirstLevel from "../scenes/FirstLevel";
import SecondLevel from "../scenes/SecondLevel";
import ThirdLevel from "../scenes/ThirdLevel";

export enum MessageType {
  PLAYER_JOINED,
  PLAYER_LEFT,
  REGULAR_MESSAGE,
}

export interface Message {
  playerName: string
  walletAddress: string
  messageType: MessageType
  content: string
  createdAt: number
}

export interface Team {
   id: string | null
   teamName: string
   teamAddress: string
   channel: string
   messages: Message[]
}

export interface Friend {
  playerName: string
  walletAddress: string
  channel: string
  messages: Message[]
  isShow: boolean
}

export interface ChatFriendRequest {
  senderAddress: string
  receiverAddress: string 
  senderName: string
  receiverName: string
}


export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chatMessages: new Array<{
      messageType: MessageType;
      chatMessage: IChatMessage;
    }>(),
    teams: [] as Team[],
    activedTeam: null as Team | null,
    isCreateTeam: false,
    isDeleteTeam: false,
    isApproveInvitation: false,
    isLeaveTeam: false,
    removedMember: "",
    chatFriends: [] as Friend[],
    activedChatFriend: null as Friend | null,
    chatFriendRequest: null as ChatFriendRequest | null,
    focused: false,
    showChat: true,
    levelName: SCENES.FIRST_LEVEL as string,
  },
  reducers: {
    setLevel: (state, action: PayloadAction<string>) => {
      state.levelName = action.payload;
      
      // Register keys for level
      var game;
      switch (action.payload) {
        case SCENES.FIRST_LEVEL:
          game = phaserGame.scene.keys.FirstLevel as FirstLevel;
          break;
        case SCENES.SECOND_LEVEL:
          game = phaserGame.scene.keys.SecondLevel as SecondLevel;
          break;
        case SCENES.THIRD_LEVEL:
          game = phaserGame.scene.keys.ThirdLevel as ThirdLevel;
          break;  
      }
    },
    setTeams: (state, action: PayloadAction<Team[]>) => {
      state.teams = action.payload
    },
    pushTeamMessage: (state, action: PayloadAction<{channel: string, messages: Message[], position?: "first" | "last"}>) => {
      const {channel, messages, position} = action.payload
      state.teams = state.teams.map((team: Team) => {
        if(team.channel === channel){
          if(position === "first"){
            return {...team, messages: [...messages, ...team.messages]}
          }else{
            return {...team, messages: [...team.messages, ...messages]}
          } 
        }else{
           return team
        }
     })
     if(state.activedTeam?.channel === channel)
     {
       state.activedTeam = state.teams.filter((team: Team) => team.channel === channel)[0]
     }
    },
    setActivedTeam: (state, action: PayloadAction<Team | null>) => {
      state.activedTeam = action.payload
    },
    setIsCreateTeam: (state, action: PayloadAction<boolean>) => {
      state.isCreateTeam = action.payload
    },
    setIsDeleteTeam: (state, action: PayloadAction<boolean>) => {
      state.isDeleteTeam = action.payload
    },
    setRemovedMember: (state, action: PayloadAction<string>) => {
      state.removedMember = action.payload
    },
    setIsApproveInvitation: (state, action: PayloadAction<boolean>) => {
      state.isApproveInvitation = action.payload
    },
    setIsLeaveTeam: (state, action: PayloadAction<boolean>) => {
      state.isLeaveTeam = action.payload
    },
    pushChatMessage: (state, action: PayloadAction<IChatMessage>) => {
      state.chatMessages.push({
        messageType: MessageType.REGULAR_MESSAGE,
        chatMessage: action.payload,
      });
    },
    addChatFriend: (state, action: PayloadAction<Friend>) => {
      if(!state.chatFriends.some(chatFriend => chatFriend.channel === action.payload.channel)){
        state.chatFriends = [...state.chatFriends, action.payload]
      }
      if(action.payload.isShow){
        state.activedChatFriend = state.chatFriends.filter(chatFriend => chatFriend.channel === action.payload.channel)[0]
      }
    },
    pushFriendMessage: (state, action: PayloadAction<{channel: string, messages: Message[]}>) => {
      const {channel, messages} = action.payload
      state.chatFriends = state.chatFriends.map((chatFriend: Friend) => {
        if(chatFriend.channel === channel){
          return {...chatFriend, messages: [...chatFriend.messages, ...messages]}
        }else{
           return chatFriend
        }
      })
      if(state.activedChatFriend?.channel === channel){
        state.activedChatFriend = state.chatFriends.filter((chatFriend: Friend) => chatFriend.channel === channel)[0]
      }
    },
    setActivedChatFriend: (state, action: PayloadAction<Friend | null>) => {
      state.activedChatFriend = action.payload
    },
    setChatFriendRequest: (state, action: PayloadAction<ChatFriendRequest | null>) => {
      state.chatFriendRequest = action.payload
    },
    showChatFriend: (state, action: PayloadAction<string>) => {
      state.chatFriends = state.chatFriends.map((chatFriend: Friend) => {
        if(chatFriend.channel === action.payload){
          return {...chatFriend, isShow: true}
        }else{
           return chatFriend
        }
      })
    },
    pushPlayerJoinedMessage: (state, action: PayloadAction<string>) => {
      state.chatMessages.push({
        messageType: MessageType.PLAYER_JOINED,
        chatMessage: {
          createdAt: new Date().getTime(),
          author: action.payload,
          content: "joined",
        } as IChatMessage,
      });
    },
    pushPlayerLeftMessage: (state, action: PayloadAction<string>) => {
      state.chatMessages.push({
        messageType: MessageType.PLAYER_LEFT,
        chatMessage: {
          createdAt: new Date().getTime(),
          author: action.payload,
          content: "left",
        } as IChatMessage,
      });
    },
    setFocused: (state, action: PayloadAction<boolean>) => {
      var game;
      switch (state.levelName) {
        case SCENES.FIRST_LEVEL:
          game = phaserGame.scene.keys.FirstLevel as FirstLevel;
          break;
        case SCENES.SECOND_LEVEL:
          game = phaserGame.scene.keys.SecondLevel as SecondLevel;
          break;
        case SCENES.THIRD_LEVEL:
          game = phaserGame.scene.keys.ThirdLevel as ThirdLevel;
          break;    
      }
    },
    setShowChat: (state, action: PayloadAction<boolean>) => {
      state.showChat = action.payload;
    },
  },
});

export const {
  pushChatMessage,
  pushTeamMessage,
  setActivedTeam,
  setTeams,
  setIsCreateTeam,
  setIsDeleteTeam,
  setIsApproveInvitation,
  setIsLeaveTeam,
  setRemovedMember,
  addChatFriend,
  pushFriendMessage,
  setActivedChatFriend,
  setChatFriendRequest,
  showChatFriend,
  setLevel,
  pushPlayerJoinedMessage,
  pushPlayerLeftMessage,
  setFocused,
  setShowChat,
} = chatSlice.actions;

export default chatSlice.reducer;
